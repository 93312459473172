/**
   * Material Buttons
   */

/**
 * Material Icon Buttons
 */
.mat-icon-button {
  //color: mat-color($primary-paperbirdTheme, A400) !important;
  color: $theme-paperbird-font-color !important;
}

.mat-icon-button.mat-gray {
  //color: mat-color($primary-paperbirdTheme) !important;
  color: $theme-paperbird-gray !important;
}

.dvtx-participant-overview-table--toolbar {
  .mat-icon-button.mat-gray {
    //color: mat-color($primary-paperbirdTheme) !important;
    color: $theme-paperbird-gray !important;
  }

  .mat-button.mat-gray {
    //color: mat-color($primary-paperbirdTheme) !important;
    color: $theme-paperbird-gray !important;
  }
}

// .mat-icon-button .mat-icon,
// .mat-list-item-content .mat-icon {
//   color: mat-color($primary-paperbirdTheme, A700) !important;
// }

.btn-outline:hover {
  background-color: mat-color($accent-paperbirdTheme, A700) !important;
}

.mat-icon-button.mat-gray:hover, .mat-button.mat-gray:hover, .mat-button.mat-gray:focus, .mat-raised-button.mat-gray:hover, .mat-raised-button.mat-gray:focus, .mat-icon-button.mat-gray:hover, .mat-icon-button.mat-gray:focus {
  color: mat-color($primary-paperbirdTheme) !important;
}

.mat-icon-button.mat-gray:hover {
  color: mat-color($primary-paperbirdTheme) !important;
}

.mat-icon-button.mat-primary {
  color: mat-color($primary-paperbirdTheme) !important;
}

.mat-icon-button.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}

.mat-button-toggle-group .mat-button-toggle-checked {
  border-bottom: 4px solid mat-color($primary-paperbirdTheme) !important;
}

.mat-button-toggle-checked {
  background-color: transparent !important;
}

.add-button {
  color: mat-color($primary-paperbirdTheme) !important;
}

.mat-sort-header-arrow {
  background: mat-color($primary-paperbirdTheme) !important;
}
.dvtx-title-editable {
  mat-icon {
    &.edit--icon-button-false {
      &:hover {
        color: mat-color($primary-paperbirdTheme) !important;
      }
    }
    &.edit--icon-button-true {
      &:hover {
        color: mat-color($primary-paperbirdTheme) !important;
      }
    }
    &.remove--icon-button-false {
      opacity: 0.3;
    }
    &.remove--icon-button-false {
      &:hover {
        color: mat-color($primary-paperbirdTheme) !important;
      }
    }
    &.remove--icon-button-true {
      &:hover {
        color: mat-color($primary-paperbirdTheme) !important;
      }
    }
  }

  &:hover {
    mat-icon.edit--icon-button-true {
      color: mat-color($primary-paperbirdTheme) !important;
    }
    mat-icon.edit--icon-button-false {
      color: mat-color($primary-paperbirdTheme) !important;
    }
    mat-icon.remove--icon-button-false {
      color: mat-color($primary-paperbirdTheme) !important;
    }
    mat-icon.remove--icon-button-true {
      color: mat-color($primary-paperbirdTheme) !important;
    }
  }
}
