.cdk-overlay-container {
  z-index: map-get($z-index-set, z-modal) !important;
  .five-f-sidebar-dialog {
    .mat-dialog-container {
      box-shadow: none !important;
      .five-f-sidebar-dialog-container {
        position: fixed;
        right: 0;
        left: auto;
        top: 0;
        bottom: 0;
        width: 798px;
        height: 100%;
        max-width: 100%;
        background: white;
        box-shadow: 0px 0px 3rem 0px rgba(0, 0, 0, 0.288);
        border-left: 10px solid $dvtx-fivef-blue-400;

        &.five-f-sidebar-dialog-container-small {
          width: 600px;
        }

        .mat-dialog-inner-wrap {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .mat-dialog {
            &-header {
              height: 64px;
              padding: 0 2rem !important;

              .mat-dialog-close {
                top: 0 !important;
                bottom: 0 !important;
                margin: auto;
                right: 2rem !important;
                box-shadow: 0 0 8px rgba(0, 0, 0, 0.2) !important;
                background: white !important;
                align-items: center;
                display: flex;
                justify-content: center;
                .mat-button-wrapper {
                  display: inline-flex;
                  line-height: 1;
                  align-items: center;
                  .mat-icon {
                    color: black !important;
                  }
                }
              }
            }
            &-content {
              max-height: none;
              max-width: none;
              height: calc(100% - 64px);
              padding: 0 2rem;
            }
            &-actions {
              height: 64px;
            }
          }
        }
      }
    }

    .mat-button-toggle-group {
      position: sticky;
      background: white;
      z-index: 99999;
      padding-top: 0;
      border: none;
    }

    .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
      border-left: none;
    }
  }
}

.cdk-overlay-transparent-auto-size-backdrop {
  width: auto !important;
  height: auto !important;
  left: auto !important;
  right: auto !important;
  top: auto !important;
  bottom: auto !important;
}
