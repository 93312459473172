@import "../../../utils/var";
@import "../../../utils/mixins";

.mat-checkbox-layout {
  margin-bottom: 0;
  max-width: 100%;
}

.mat-checkbox-label {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: $dvtx-fivef-blue-300;
  max-width: 100%;
  width: 100%;
  @include text-crop;
}

.mat-checkbox-frame {
  background-color: $dvtx-white;
  border: 1px solid $dvtx-fivef-blue-100 !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $dvtx-action;
  border: 1px solid $dvtx-action;
}

/*sec-checkbox*/
.sec-checkbox.mat-checkbox-checked .mat-checkbox-frame {
  border: none !important;
}

.sec-checkbox .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.sec-checkbox.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: transparent;
  border: none !important;
}

.sec-checkbox .mat-checkbox-checkmark-path {
  stroke-width: 4.66667px !important;
  stroke: $dvtx-action !important;
}

.sec-checkbox .mat-checkbox-label {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: $dvtx-fivef-blue-500;
}
