// Custom angular.material.io configuration.
// Used at the moment for the dropdown select.

@import '../../../../../node_modules/@angular/material/theming';
@import "../../utils/var";

/*
Plus imports for other components in your app.

Include the common styles for Angular Material. We include this here so that you only
have to load a single css file for Angular Material in your app.
Be sure that you only ever include this mixin once!
 */
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$dvtx-app-primary: mat-palette($mat-light-blue, 500);
$dvtx-app-accent:  mat-palette($mat-green, A700);
$dvtx-app-warn:    mat-palette($mat-red, A700);

$dvtx-app-primary: map_merge($dvtx-app-primary, (
  default: $dvtx-action-blue
));

// Create the theme object (a Sass map containing all of the palettes).
$dvtx-app-theme: mat-light-theme($dvtx-app-primary, $dvtx-app-accent, $dvtx-app-warn);

// Define the application's dark theme
$primary-dark: mat-palette($mat-deep-purple);
$accent-dark:  mat-palette($mat-green, A200, A100, A400);

// Use mat-dark-theme instead of mat-light-theme
$dark-theme: mat-dark-theme($primary-dark, $accent-dark);


$primary-indigo: mat-palette($mat-indigo);
$accent-indigo: mat-palette($mat-pink, A200, A100, A400);
$theme-indigo: mat-light-theme($primary-indigo, $accent-indigo);

$primary-purple: mat-palette($mat-deep-purple);
$accent-purple: mat-palette($mat-amber, A200, A100, A400);
$theme-purple: mat-light-theme($primary-purple, $accent-purple);



// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($dvtx-app-theme);
