.five-f-content-editable-container {
  background-color: transparent;
  transition: background-color .3s 0s cubic-bezier(0.4, 0, 0.2, 1);

  &--editor {
    padding: 0;
    transition: padding .3s 0s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &:hover {
    background-color: $dvtx-fivef-blue-50;
    cursor: text;
  }

  &--editor:hover {
    padding: 0.3rem 0.5rem;
  }
}
