$fa--base-font-size: 1.125rem;
$fa--base-color: map_get($dvtx-color-palette, cool-grey-300);

.fa.fa-dvtx {
  font-size: $fa--base-font-size;
  color: $fa--base-color;
}

.mat-icon-button[disabled] {
  color: rgba(0, 0, 0, 0.26) !important;
  cursor: default !important;
}
