.mat-table {
  .mat-row {
    &.row-controls {
      position: relative;
      padding-bottom: 40px;
      @include transition(300, all);

      &.with-comment {
        padding-bottom: 90px;
      }

      .row-control {
        position: absolute;
        bottom: 0;
        right: $content-gap;
        left: $content-gap;
        width: calc(100% - #{$base-gap});

        &.with-comment {
          bottom: -15px;
        }
      }
    }
  }
}

