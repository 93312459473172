// CARDS BASIC
.card {
    border: 0;
    .card-title a {
        color: #424242;
        @include transition (0.4s);
        &:hover {
            color: #616161;
            @include transition (0.4s);
        }
    }
    img {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        @extend .img-fluid;
    }
    .card-block {
        position:relative;
    }
    .card-footer {
        padding-bottom: 0;
        font-size:1.2rem;
        &:after {
            display:block;
            content: "";
            clear:both;
        }
        .card-link {
            color: inherit;
        }
    }
}
.card-block{
    .card-heading,
    .card-subheading{
        background:$dvtx-cool-grey-50;
        h2,
        h3{
            margin-bottom:30px;
        }
        h2+h3{
            margin:15px 0 10px;
        }
        h3:only-child{
            margin:0;
        }
        h2{
            margin:8px 0 0;
        }
        +.card-frame{
            border:0;
        }
    }
}
.card-frame,
.card-heading,
.card-subheading{
    margin-left:-$card-spacer-x;
    margin-right:-$card-spacer-x;
}
.card-frame{
    border-top:1px solid $dvtx-cool-grey-100;
    &:first-child{
        border:0;
    }
    h4:only-child{
        margin-bottom:30px;
    }
}
.card-heading{
    margin-top:-$card-spacer-x;
    padding:$card-spacer-x;
}
.card-frame,
.card-subheading{
    padding:$card-spacer-x*2 $card-spacer-x*2 $card-spacer-x*0.5;
    @include mq-sm-down{
        padding:$card-spacer-x $card-spacer-x $card-spacer-x*0.5;
    }
}
.card-subheading{
    padding-bottom:$card-spacer-x*2;
    @include mq-sm-down{
        padding-bottom:$card-spacer-x;
    }
}
.card-frame-footer{
    padding-top:$card-spacer-x*1.5;
}

// Jumbtotron
.jumbotron {
    background-color: #fff;
    padding: 2rem;
    &.m-1,
    &.m-2,
    &.m-3 {
        z-index: 50;
        position: relative;
        margin-left: 3%;
        margin-right: 3%;
    }
    &.m-1 {
        margin-top: -20px;
    }
    &.m-2 {
        margin-top: -30px;
    }
    &.m-3 {
        margin-top: -40px;
    }
}

// .list-group fix
.list-group-item {
    width:100%;
}

// edge header
.edge-header {
    display:block;
    height:278px;
    background-color:#ccc;
}

.free-bird {
    margin-top:-100px; 
}

// Form sets

.card .form-header {
    color: #fff;
    text-align: center;
    margin-top: -50px;
    margin-bottom: 3rem;
    padding: 1rem;
    @extend .z-depth-1-half;
    @include border-radius(2px);
    h3 {
        margin: 0;
        padding: 0.7rem;
    }
}
.call .fa {
    margin-right: 5px;
}
