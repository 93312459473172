@import "../../../utils/var";
@import "../../../utils/mixins";

.dvtx-table-accordion {
  .mat-expansion-panel {

    .mat-expansion-panel-header {
      flex-direction: row-reverse;

      .mat-content {
        align-content: center;
      }

      .mat-expansion-indicator {
        margin-right: $content-gap;
      }

      .mat-expansion-panel-header-title {
        align-content: center;
      }

      .mat-expansion-panel-header-description {
        align-content: center;
        justify-content: flex-end;
        margin-right: 0;
      }
    }

    .mat-expansion-panel-body {
      margin-left: 0;
      margin-right: 0;
    }

    .contact-person-table {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      min-height: 48px;
      padding: 1rem 16px 0;

      &.danger-bg {
        background-color: map-get($bg-colors, bg-danger);;
      }

      .cpt-cell {
        flex: 1 1 auto;
        overflow: hidden;
        word-wrap: break-word;
        min-width: 10%;
        padding-right: 1rem;

        &:last-child {
          padding-right: 0;
        }

        &.cpt-alert-icon {
          flex: 0 1 24px;
          min-width: 24px;
          margin-right: 7px;
        }

        &.cpt-check {
          flex: 0 1 24px;
          min-width: 24px;
        }

        &.cpt-first-name {
          flex: 1 1 150px;
          min-width: 150px;
        }

        &.cpt-last-name {
          flex: 1 1 150px;
          min-width: 150px;
        }

        &.cpt-role {
          flex: 1 1 120px;
          min-width: 120px;
        }

        &.cpt-email {
          flex: 1 1 215px;
          min-width: 215px;
        }

        &.cpt-mobile {
          display: flex;
          flex: 1 1 152px;
          min-width: 152px;
        }

        &.cpt-phone {
          flex: 1 1 130px;
          min-width: 130px;
        }

        &.cpt-actions {
          display: flex;
          flex: 0 1 110px;
          min-width: 110px;
        }
      }
    }
  }
}

.timeline-aside {
  .mat-expansion-panel {
    .mat-expansion-panel-body {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
