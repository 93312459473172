/**
   * Material and FiveF Card layout.
   */
.mat-card .mat-card-header .mat-card-title {
  background-color: unset !important;
}

.five-f-card .mat-card-header .mat-card-header-text .mat-card-title {
  background: unset !important;
}

.mat-card-title,
.five-f-card .mat-card-header .mat-card-header-text {
  color: $theme-curacon-font-color !important;
  h1, h2, h3, h4, h5 {
    color: $theme-curacon-font-color !important;
  }
}

dvtx-notifications .mat-card-title, dvtx-notifications .mat-tabs .mat-tab-header,
dvtx-profile .mat-card-title, dvtx-profile .mat-tabs .mat-tab-header,
dvtx-organization-card .mat-card-title, dvtx-organization-card .mat-tabs .mat-tab-header,
mat-card.user-profile .mat-card-title, mat-card.user-profile .mat-tabs .mat-tab-header,
dvtx-project-milestone .mat-card-title, dvtx-project-milestone .mat-tabs .mat-tab-header,
dvtx-activities-panel .mat-card-title, dvtx-activities-panel .mat-tabs .mat-tab-header {
  background: mat-color($primary-curaconTheme, A700) !important;
  color: white !important;
  h1, h2, h3, h4, h5 {
    color: white !important;
  }
}

dvtx-profile .five-f-card.five-f-card-blue-header .mat-card-header .mat-card-header-text,
dvtx-organization-card .five-f-card.five-f-card-blue-header .mat-card-header .mat-card-header-text,
mat-card.user-profile .five-f-card.five-f-card-blue-header .mat-card-header .mat-card-header-text,
dvtx-project-milestone .five-f-card.five-f-card-blue-header .mat-card-header .mat-card-header-text,
dvtx-activities-panel .five-f-card.five-f-card-blue-header .mat-card-header .mat-card-header-text {
  background-color: mat-color($primary-curaconTheme, A700) !important;
  color: white !important;
  h1, h2, h3, h4, h5 {
    color: white !important;
  }
}
