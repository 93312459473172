.dvtx-sidebar-navigation-right {
  .active:not(.nav-item) a.sidebar--link {
    color: $theme-coloredFiveF-font-color !important;
    text-decoration: none !important;

    &:active {
      color: $theme-coloredFiveF-font-color !important;
      border-color: mat-color($primary-coloredFiveFTheme) !important;
    }
    &:hover {
      color: mat-color($primary-coloredFiveFTheme) !important;
      border-color: mat-color($primary-coloredFiveFTheme) !important;
    }
  }

  .active:not(.nav-item) a.dvtx-active-sidebar-link {
    color: mat-color($primary-coloredFiveFTheme) !important;
    border-color: mat-color($primary-coloredFiveFTheme) !important;
  }
}

.dvtx-sidebar-navigation-left {
  .active:not(.nav-item) a.sidebar--link {
    color: $theme-coloredFiveF-font-color !important;
    text-decoration: none !important;

    &:active {
      color: $theme-coloredFiveF-font-color !important;
      border-color: mat-color($primary-coloredFiveFTheme) !important;
    }

    &:hover {
      color: mat-color($primary-coloredFiveFTheme) !important;
      border-color: mat-color($primary-coloredFiveFTheme) !important;
    }
  }

  .active:not(.nav-item) a.dvtx-active-sidebar-link {
    color: mat-color($primary-coloredFiveFTheme) !important;
    border-color: mat-color($primary-coloredFiveFTheme) !important;
    text-decoration: none;
  }
}

.dvtx-sidebar-toggle {
  &:hover {
    background-color: mat-color($primary-coloredFiveFTheme) !important;
  }
}

.nav-item-locale-switcher {
  &.active,
  &:hover {
    & > a.dvtx-show-bottom-line-on-hover {
      color: mat-color($primary-coloredFiveFTheme);
      box-shadow: inset 0 -4px 0 0 mat-color($primary-coloredFiveFTheme) !important;
    }
  }
}

.dvtx-sidebar-resizer {
  &:hover {
    border-left: 1px solid mat-color($primary-coloredFiveFTheme) !important;;
  }
}
