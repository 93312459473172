.mat-icon-button {
  // .mat-button-wrapper {
  //   display: flex;
  //   align-items: center;
  //   .mat-icon {
  //     margin-right: 0.25rem;
  //     line-height: 1;
  //     height: 20px;
  //     width: 20px;
  //     font-size: 20px;
  //   }
  // }
  // .mat-button-focus-overlay {
  //   opacity: 0 !important;
  //   display: none !important;
  // }
}
.mat-icon-button-with-text {
  width: auto !important;
  .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .mat-icon {
      font-size: 18px;
      height: 18px;
      width: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 2px;
    }
  }

  .mat-button-focus-overlay {
    display: none !important
  }
}
