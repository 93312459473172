.expected-structure {
  &__table {
    width: 100%;

    & td {
      border: 1px solid $dvtx-blue-light;
      padding: $content-gap*0.5;
    }
  }
}

table.dvtx-table {
  background-color: $dvtx-white;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: $content-gap;
  width: 100%;
  max-width: 100%;

  & th,
  & td {
    padding: $content-gap*0.5;
  }

  & th {
    background-color: $dvtx-bg;
    color: rgba(0, 0, 0, 0.54);
    font-size: 0.8571em;
    font-weight: 500;
    word-wrap: break-word;
  }

  & td {
    &:first-child {
      padding-left: $content-gap;
    }
    &:last-child {
      padding-right: $content-gap;
    }

    &.license-td {
      min-width: 10rem;
    }

    &.username-td {
      min-width: 12rem;
    }
  }

  & tr:not(.bordless-tr) {

    td {
      border-top: 1px solid $dvtx-cool-grey-50;
    }

    &:first-child {
      td {
        border-top: 0 none;
      }
    }
  }

  &.diff-table {
    background-color: transparent !important;

    @include mq-xs-down {
      & th {
        display: none;
      }

      & td {
        display: block;

        &:first-child {
          padding: $content-gap*0.5;
        }

        &:before {
          content: attr(data-th)": ";
          display: inline-block;
          width: 6.5em;
        }
      }
    }
  }
}

span[class^="diff"] {
  padding: 0 3px;
}

.diff {
  &__description {
    background-color: $dvtx-action-light;
  }

  &__removed,
  &__removed-header {
    text-align: center;
  }

  //&__removed {
  //  text-decoration: line-through;
  //}

  &__added {
    text-align: center;
  }

  &__to_arrow {
    display: flex;
    justify-content: center;
  }

  &__background {
    &-red {
      background-color: $dvtx-danger-light;
    }

    &-green {
      background-color: $dvtx-success-light;
    }
  }
}
