.five-f-mat-table {
  .mat-table {
    background: none;
    width: 100% !important;
  }

  .mat-header-row,
  .mat-row {
    border: none;
  }

  .mat-header-row.mat-table-sticky {
    top: -40px !important;
    background-color: rgb(245 245 245 / 80%);
  }

  .mat-cell,
  .mat-header-cell {
    flex: 1 0 160px;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mat-cell:not(:last-child) {
    border-right: 1px solid $dvtx-cool-grey-50;
  }

  .mat-row {
    margin-bottom: 4px;
    padding: 0;
    background: white;
    // border-left: 4px solid;
    // cursor: pointer;

    .mat-cell {
      background: white;
    }

    &.active,
    &:HOVER {
      background: lighten($dvtx-cool-grey-50, 3%) !important;
      .mat-cell {
        background: lighten($dvtx-cool-grey-50, 3%) !important;
      }
    }
  }

  table {
    width: 100% !important;

    tr {
      width: 100%;
      height: 54px !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    thead {
      background: transparent;

      tr {
        height: 40px !important;
        // Scrollbar at right border
        padding-right: 6px;
      }
    }

    tbody {
      background: transparent;

      tr {
        background-color: white;
        border-bottom: 1px solid $dvtx-cool-grey-25;
        height: 55px !important;
        transition: background-color 0.1s ease-in-out;

        &:hover {
          background-color: #f5f5f5;
        }
      }
    }

    th {
      flex: 1;
      background: transparent;
      text-align: center;
      font-size: 0.75rem;
      font-weight: 400;
      color: #797b7b;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    td {
      height: 100%;
      border-top: 1px solid $dvtx-cool-grey-25;
      border-left: 1px solid $dvtx-cool-grey-25;
      border-right: 1px solid $dvtx-cool-grey-25;
      vertical-align: middle;

      padding-left: 5px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .hide-row {
    display: none;
  }

  .cdk-column,
  .five-f-column {
    &-icon,
    &-dropdown {
      flex: 0 0 52px;
      padding: 0 1rem;
    }

    &-name,
    &-worker-name,
    &-title,
    &-email,
    &-path {
      //flex: 1 0 400px;
      justify-content: flex-start;
      padding: 0 1rem;
      min-width: 180px;
      flex-grow: 1;
      padding-left: 1rem;
    }

    &-id {
      justify-content: flex-start;
      padding: 0 1rem;
      min-width: 150px;
      max-width: 230px;
      flex-grow: 1;
      padding-left: 1rem;
    }

    &-ident {
      flex: 0 0 150px;
      padding: 0 1rem;
      justify-content: flex-start;
      padding-left: 1rem;
    }

    &-first-name,
    &-last-name,
    &-username {
      flex: 1 1 200px;
      padding-left: 1rem;
      justify-content: flex-start;
    }

    &-count,
    &-process-count {
      flex: 0 0 80px;
    }

    &-avatar {
      padding-left: 0;
      flex: 0 0 60px;
    }

    &-creator {
      flex: 0 0 80px;
    }

    &-updated-at,
    &-created-at,
    &-locked-at,
    &-last-sign-in-at,
    &-due-date {
      flex: 0 0 150px;
    }

    &-licence-type {
      flex: 0 0 100px;
    }

    &-actions {
      flex: 0 0 90px;
      padding: 0 0.5rem !important;
      justify-content: flex-end;
      left: unset !important;
      right: 0 !important;
      @supports (-ms-ime-align:auto) {
        position: relative !important;
      }
      @supports (-ms-ime-align:auto) {
        position: relative !important;
      }
    }
    &-invitation-actions {
      flex: 0 0 60px;
      padding: 0 0.5rem !important;
      justify-content: flex-end;
      left: unset !important;
      right: 0 !important;
      @supports (-ms-ime-align:auto) {
        position: relative !important;
      }
      @supports (-ms-ime-align:auto) {
        position: relative !important;
      }
    }
  }
}

/**
 * Multi type row table setup with upper five-f-mat-table design.
 * Setup taken form DMS Document table.
 */
.five-f-mat-table-multi-row {
  background-color: transparent;

  .mat-table {
    background-color: transparent;
  }

  table {
    width: 100%;

    td, th {
      padding-left: 20px;
      word-break: break-all;
    }
  }

  .mat-header-row,
  .mat-row {
    border: none;
  }

  .mat-header-row.mat-table-sticky {
    top: -40px !important;
    background-color: rgb(245 245 245 / 80%);
  }

  .mat-cell:not(:last-child) {
    border-right: 1px solid $dvtx-cool-grey-50;
  }

  .mat-row {
    background: white;
    // border-left: 4px solid;
    // cursor: pointer;

    .mat-cell {
      background: white;
    }

    &.active,
    &:HOVER {
      background: lighten($dvtx-cool-grey-50, 3%) !important;
      .mat-cell {
        background: lighten($dvtx-cool-grey-50, 3%) !important;
      }
    }
  }

  .expanded-details {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  tr.collapsed-detail-row {
    height: 0;
  }

  .mat-row .mat-cell {
    padding: 0 0 0 15px !important;
  }

  .mat-header-row .mat-header-cell {
    padding: 0 0 0 15px !important;
    background-color: transparent;
  }

  .mat-header-row .mat-header-cell:first-child {
    padding: 0 0 0 15px !important;
  }

  td.mat-cell, td.mat-footer-cell, th.mat-header-cell {
    padding: 0;
    border-bottom-width: 1px;
    border-bottom-color: $dvtx-fivef-blue-25;
    border-bottom-style: solid;
  }

  .cdk-column {
    &-type {
      flex: 0 0 120px;
      max-width: 120px;
    }

    &-select {
      flex: 0 0 50px;
      width: 50px;
      align-items: center;
      justify-content: center;
    }
  }
}
