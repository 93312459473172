.dvtx-client-nav-bar.five-f-card-profile-user.mat-tab-nav-bar.mat-primary,
.dvtx-client-nav-bar.five-f-card-profile-user .mat-tab-nav-bar.mat-primary {
  background: mat-color($primary-curaconTheme, A700) !important;

  a {
    color: white !important;
  }
}

.mat-tab-nav-bar, .mat-tab-header {
  border-bottom: none !important;
}

.mat-tab-links {
  a.mat-tab-link {
    color: #1d1d1b !important;
  }

  a.mat-tab-link.mat-tab-label-active {
    color: mat-color($primary-curaconTheme) !important;
  }
}
