dvtx-folder-selection-dialog .folder-actions .action button:hover:not([disabled]) {
  color: mat-color($primary-paperbirdTheme) !important;
}

dvtx-folder-tree mat-tree-node.tree-node--selected {
  box-shadow: 0 0 0 2px inset mat-color($primary-paperbirdTheme) !important;
}

dvtx-folder-tree mat-tree-node:hover {
  background-color: mat-color($accent-paperbirdTheme) !important;
}

dvtx-process-tree-node a:not(.dropdown-item):not(.mat-list-item):not([dvtxfileupload]):not(.nav-link):not(.mat-tab-link) .dvtx-active-link-icon,
mat-nested-tree-node a:not(.dropdown-item):not(.mat-list-item):not([dvtxfileupload]):not(.nav-link):not(.mat-tab-link) .dvtx-active-link-icon {
  mat-icon {
    color: mat-color($primary-paperbirdTheme) !important;
  }
}

// NOTE: Link overrides are located at _atag_link_nav.scss
