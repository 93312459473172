/**
   * Alert box.
   */
dvtx-alert .alert-info {
  border-color: mat-color($accent-paperbirdTheme, A700) !important;
}

dvtx-alert .alert-info .alert--icon {
  background: mat-color($accent-paperbirdTheme, A700) !important;
}
