@import "../../../utils/var";
@import "../../../utils/mixins";

.leave-comment {
  align-items: center;

  .btn {
    width: 40px;
    height: 40px;
    margin: 0!important;
    padding: .5em!important;

    .fa {
      margin: 0 5px;
    }
  }
}
