.paperbirdTheme {
  // Generic CSS color setup. See base/_colors for all
  // existing CSS colors variables.
  --five-f-action-color: #e4732b;
  --five-f-indicator-background-color-unread: #e4732b;

  --five-f-filter-beta-tag-background-color: #e4732b;
  --five-f-filter-badge-background-color: #e4732b;

  --five-f-filter-button-background-color-active: #e4732b;

  // Button color hover override
  --five-f-icon-button-color-hover: #e4732b;

  // General overrides (order important)
  // 1. Base overrides
  // 2. Functionality overrides
  // 3. Module dependent overrides.
  @import "paperbird/colors";
  @import "paperbird/font";
  @import "paperbird/avatar";
  @import "paperbird/atag_link_nav";
  @import "paperbird/button";
  @import "paperbird/card";
  @import "paperbird/checkbox";
  @import "paperbird/dialog";
  @import "paperbird/progress_bar";
  @import "paperbird/radio_button";
  @import "paperbird/table";
  @import "paperbird/tabs";
  @import "paperbird/stepper";

  // Special functionalities
  @import "paperbird/alert";
  @import "paperbird/dms_selection_folder_tree";
  @import "paperbird/dropdown_menu";
  @import "paperbird/listing_box";
  @import "paperbird/tags_labels";
  @import "paperbird/person-selector";
  @import "paperbird/upload";
  @import "paperbird/sidebar";

  // View/Module overrides
  @import "paperbird/collecto";
  @import "paperbird/signature";
  @import "paperbird/dashboard_calendar";
  @import "paperbird/page-header";
}
