.mat-card {

  & .mat-card-header .mat-card-title {
    background-color: $dvtx-bg;
    font-size: 1rem;
    padding: 1.5em $content-gap;
    margin-bottom: 0;

    &.flex {
      display: flex;
      flex-wrap: wrap;
    }

    & h1,
    & h2,
    & h3 {
      margin: 0;
      line-height: 1.1;
    }
  }

  & .mat-card-content {
    padding: $content-gap;

    &:first-child {
      padding-top: $content-gap*2;
    }

    &:only-child {
      padding-top: $content-gap;
    }

    &.mat-card-table-content {
      padding: 0;
    }

    & .mat-card-footer {
      margin: 0;
    }
  }

  & > .mat-card-actions {
    margin-left: 0;
    margin-right: 0;
    padding: $content-gap;
  }

  .mat-radio-group .mat-radio-button {
    margin-top: 0.5rem;
    margin-bottom: 0.25rem;
  }

  &.p-0 {
    & .mat-card-footer {
      margin: 0;

      .mat-card-actions {
        margin: 0;
        padding: $content-gap;
      }
    }
  }

  &.card-content-devided {
    padding: 0;

    & .mat-card-content {
      border-bottom: 1px solid $dvtx-fivef-blue-25;
    }

    & .mat-card-footer {
      margin: 0;
    }
  }

  &.card-aside {
    padding: 0;

    &:not([class*=mat-elevation-z]) {
      @include box-shadow();
    }

    .mat-card-title {
      background-color: $dvtx-bg;
      font-size: 1rem;
      padding: 1.5em $content-gap;
      margin-bottom: 0;
    }

    & .mat-card-content {
      padding: $content-gap;
      border-bottom: 1px solid $dvtx-fivef-blue-25;
    }

    .mat-list {
      .mat-list-item {
        font-size: 1rem;
        padding: $content-gap;
        border-bottom: 1px solid $dvtx-fivef-blue-25;
        &.active {
          background-color: $dvtx-bg;
          color: $dvtx-action;
        }
      }
      .mat-item-icon {
        position: absolute;
        top: 0;
        &.mat-Suffix {
          right: -0.5em;
        }
        &.mat-Auffix {
          left: 0;
        }

      }
    }

    & .mat-card-footer {
      margin: 0;
    }
  }

  .mat-table {
    margin-bottom: $content-gap;
    max-width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    @include mq-lg-down {
      width: 100%;
    }

    .mat-row,
    .mat-header-row {
      &:last-child {
        border-color: transparent;
      }
      @include mq-lg-down {
        min-width: 760px;
      }
    }

    .mat-header-row {
      background-color: $dvtx-bg;
      border-color: transparent;
      padding: 0 $content-gap;

      .mat-header-cell {
        background-color: $dvtx-bg;
        padding: $content-gap*0.5;
        &:first-child {
          padding-left: 0;
        }
      }
    }

    .mat-row {
      padding: 0 $content-gap;

      .mat-cell {
        padding: $content-gap*0.5;
        &:first-child {
          padding-left: 0;
        }

        .dvtx-datapicker {
          .mat-form-field-wrapper {
            // padding-bottom: 0;
          }
        }
      }
    }
  }
}
