$primary-curacon: (
  50:  #c62a34,
  100: #c62a34,
  200:  #c62a34,
  300:  #c62a34,
  400:  #c62a34,
  500:  #c62a34,
  600:  #c62a34,
  700:  #c62a34,
  800: #c62a34,
  900:  #c62a34,
  A100:  #de636b,
  A200:  #c62a34,
  A400:  #c62a34,
  A700: #760000,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);
$accent-curacon: (
  50:  #c62a34,
  100: #c62a34,
  200:  #c62a34,
  300:  #c62a34,
  400:  #c62a34,
  500:  #c62a34,
  600:  #c62a34,
  700:  #c62a34,
  800: #c62a34,
  900:  #c62a34,
  A100:  #c62a34,
  A200:  #c62a34,
  A400:  #c62a34,
  A700: #760000,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$primary-curaconTheme: mat-palette($primary-curacon);
$accent-curaconTheme: mat-palette($accent-curacon, A200, A100, A400);
$theme-curacon: mat-light-theme($primary-curaconTheme, $accent-curaconTheme);
$background-color: rgb(229, 225, 221);
$background: map-get($theme-curacon, background);
$background: map_merge($background, (background: $background-color));
$theme-curacon: map_merge($theme-curacon, (background: $background));

$theme-link-hover-color: mat-color($primary-curaconTheme);
$dvtx-action-blue: mat-palette($primary-curacon);
$theme-curacon: map_merge($theme-curacon, (background: $background));
$theme-curacon-font-color: #555b5e;
$theme-curacon-default-icon-color: $theme-curacon-font-color;
$theme-curacon-gray: #74767a;
$theme-curacon-light-gray: rgb(229, 225, 221);
$theme-curacon-indicator-color: rgb(118, 0, 0);

@include angular-material-theme($theme-curacon);

h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5, p {
  color: $theme-curacon-font-color !important;
}
