.curaconTheme {
  --five-f-link-color: #c62a34;
  --five-f-link-color-hover: #c62a34;

  --five-f-action-color: #c62a34;
  --five-f-indicator-background-color-unread: #760000;
  --five-f-filter-beta-tag-background-color: #c62a34;
  --five-f-filter-badge-background-color: #c62a34;

  --five-f-filter-button-background-color-active: rgb(118, 0, 0);

  // Button color hover override
  --five-f-icon-button-color-hover: #c62a34;

  // General overrides (order important)
  // 1. Base overrides
  // 2. Functionality overrides
  // 3. Module dependent overrides.
  @import "curacon/colors";
  @import "curacon/font";
  @import "curacon/avatar";
  @import "curacon/atag_link_nav";
  @import "curacon/button";
  @import "curacon/card";
  @import "curacon/filters";
  @import "curacon/checkbox";
  @import "curacon/dialog";
  @import "curacon/progress_bar";
  @import "curacon/radio_button";
  @import "curacon/table";
  @import "curacon/tabs";
  @import "curacon/stepper";

  // Special functionalities
  @import "curacon/alert";
  @import "curacon/dms_selection_folder_tree";
  @import "curacon/dropdown_menu";
  @import "curacon/listing_box";
  @import "curacon/tags_labels";
  @import "curacon/person-selector";
  @import "curacon/upload";
  @import "curacon/sidebar";

  // View/Module overrides
  @import "curacon/collecto";
  @import "curacon/signature";
  @import "curacon/dashboard_calendar";
  @import "curacon/page-header";

  a:not(.dropdown-item):not(.mat-list-item):not([dvtxfileupload]):not(.nav-link),
  .status-color-info {
    // color: mat-color($primary-curaconTheme) !important;
    color: #555b5e;
    //border-bottom: 0.125rem solid #a70c15;
    //will-change: color;
    //transition: color .2s ease-out;

    &:hover {
      color: #a70c15;
    }
  }

  .dvtx-locale-switcher > ul > li > a {
    color: rgb(128, 128, 128) !important;
  }

  li.nav-item.dropdown .dropdown-menu > li.dropdown-item-wrapper .dropdown-item:hover, li.nav-item.dropdown .dropdown-menu > li.dropdown-item-wrapper .dropdown-item:active {
    background-color: mat-color($primary-curaconTheme) !important;
    color: white !important;
  }

  .mat-dialog-inner-wrap {
    border-color: mat-color($primary-curaconTheme) !important;
  }

  .drag-n-drop__button {
    p {
      margin: 0px !important;
    }
  }

  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    border: 1px solid mat-color($primary-curaconTheme, A700) !important;
  }

  dvtx-truncated-description .show--less--link, dvtx-truncated-description .show--more--link {
    color: mat-color($primary-curaconTheme) !important;

  }

  .five-f-dropdown-menu-items-toggler.mat-primary {
    background: mat-color($primary-curaconTheme) !important;
    color: white !important;
  }

  .five-f-dropdown-menu-items li button:hover, .five-f-dropdown-menu-items li .mat-button:hover {
    color: mat-color($primary-curaconTheme) !important;
  }

  dvtx-alert .alert-info {
    border-color: mat-color($primary-curaconTheme, A700) !important;
  }

  dvtx-alert .alert-info .alert--icon {
    background: mat-color($primary-curaconTheme, A700) !important;
  }

  .dropdown .dropdown-menu .dropdown-item:hover, .dropdown .dropdown-menu .dropdown-item:active {
    background-color: mat-color($primary-curaconTheme) !important;
    color: white !important;
  }

  .mat-menu-item:hover:not([disabled]):not(.simple-confirm), .curaconTheme .mat-menu-item.cdk-program-focused:not([disabled]):not(.simple-confirm), .curaconTheme .mat-menu-item.cdk-keyboard-focused:not([disabled]):not(.simple-confirm), .curaconTheme .mat-menu-item-highlighted:not([disabled]):not(.simple-confirm) {
    background-color: mat-color($primary-curaconTheme) !important;
    color: white !important;

    .mat-icon {
      color: white !important;
    }

    sub {
      color: white !important;
    }
  }

  .mat-icon:hover {
    background: unset !important;
  }

  dvtx-person-selector-autocomplete .mat-icon.custom--edit--icon {
    background: unset !important
  }

  .addLabel-Btn:hover .addLabel-icon svg path,
  .addLabel-Btn:hover .addLabel-text {
    fill: mat-color($primary-curaconTheme) !important;
    color: mat-color($primary-curaconTheme) !important;
  }

  .mat-table {
    background: unset !important;
  }

  .mat-tab-nav-bar, .mat-tab-header {
    border-bottom: none !important;
  }

  dvtx-folder-selection-dialog .folder-actions .action button:hover:not([disabled]) {
    color: mat-color($primary-curaconTheme) !important;
  }

  dvtx-folder-tree mat-tree-node.tree-node--selected {
    box-shadow: 0 0 0 2px inset mat-color($primary-curaconTheme) !important;
  }

  .five-f-signature-settings-default-image .five-f-signature-preview-action-buttons .add-button:hover {
    background: unset !important
  }

  .fr-box.fr-basic.fr-top .fr-wrapper .fr-element.fr-view:focus {
    border: 1px solid mat-color($primary-curaconTheme) !important;
  }

  .five-f-beta-feature-button-tag {
    background-color: mat-color($primary-curaconTheme) !important;
  }

  .list-item mat-icon:not(.document__icon):hover {
    color: mat-color($primary-curaconTheme) !important;
  }

  .collector-create-from .textarea__input:focus {
    border-color: mat-color($primary-curaconTheme) !important;
  }

  .progress-bar-container .base-bar {
    background-color: mat-color($primary-curaconTheme) !important;
  }
}
