// Tasks page column buttons have a blue override (expand/process button).
dvtx-tasks-listing-headline {
  button:not([disabled]):hover {
    .mat-icon {
      color: mat-color($primary-coloredFiveFTheme) !important;
    }
  }
}

/**
 * Label functionality overrides.
 */
.addLabel-Btn:hover .addLabel-icon svg path,
.addLabel-Btn:hover .addLabel-text {
  fill: mat-color($primary-coloredFiveFTheme) !important;
  color: mat-color($primary-coloredFiveFTheme) !important;
}
