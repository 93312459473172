.indigoTheme {
  @include angular-material-theme($theme-indigo);

  .mat-button-toggle-group .mat-button-toggle-checked {
    border-bottom: 4px solid mat-color($primary-indigo) !important;
  }

  .add-button {
    color: mat-color($primary-indigo) !important;
  }

  .mat-icon-button.mat-gray:hover {
    color: mat-color($primary-indigo) !important;
  }

  .mat-button-toggle-checked {
    background-color: transparent !important;
  }

  .avatar .avatar-icon {
    background-color: mat-color($primary-indigo) !important;
    border-color: mat-color($primary-indigo) !important;
  }

  .mat-dialog-inner-wrap {
    border-color: mat-color($primary-indigo) !important;
  }
}

.purpleTheme {
  @include angular-material-theme($theme-purple);

  .mat-button-toggle-group .mat-button-toggle-checked {
    border-bottom: 4px solid mat-color($primary-purple) !important;
    border: 1px dashed mat-color($primary-purple) !important;
  }

  .add-button {
    color: mat-color($primary-purple) !important;
  }

  .mat-icon-button.mat-gray:hover {
    color: mat-color($primary-purple) !important;
  }

  .mat-button-toggle-checked {
    background-color: transparent !important;
  }

  .avatar .avatar-icon {
    background-color: mat-color($primary-purple) !important;
    border-color: mat-color($primary-purple) !important;
  }

  .mat-dialog-inner-wrap {
    border-color: mat-color($primary-purple) !important;
  }
}

.darkTheme {
  @include angular-material-theme($dark-theme);

  div {
    color: white;
  }

  body {
    background-color: darkgray;
  }

  .mat-sidenav-content {
    background-color: darkgrey !important;
  }

  mat-table {
    div {
      color: white;

    }
  }

  .mat-card .mat-card-header .mat-card-title {
    background-color: unset !important;
  }

  .five-f-card .mat-card-header .mat-card-header-text .mat-card-title {
    background: unset !important;
  }

  mat-cell {
    background-color: rgb(66, 66, 66) !important;
    background: rgb(66, 66, 66) !important;

  }

  .collecto--table .mat-row .mat-cell {
    background: rgb(66, 66, 66) !important;

  }

  dvtx-activities-panel .list-body .list-element, dvtx-latest-updates .list-body .list-element, dvtx-activities-overdue-events .list-body .list-element {
    background-color: rgb(66, 66, 66) !important;

  }

  .calendar .calendar-body .day {
    background-color: unset !important;
  }
}
