/**
 * Base widget container.
 */
.five-f-widget {
  border-top-left-radius: var(--five-f-widget-radius);
  border-top-right-radius: var(--five-f-widget-radius);
  border-bottom-left-radius: var(--five-f-widget-radius);
  border-bottom-right-radius: var(--five-f-widget-radius);
}

/**
 * Widget header.
 */
.five-f-widget-header {
  background-color: var(--five-f-widget-header-bg-color);
  border-top-left-radius: var(--five-f-widget-radius);
  border-top-right-radius: var(--five-f-widget-radius);
  padding: 0.3rem 0.5rem 0.3rem 0.75rem;
}

/**
 * Widget body container.
 */
.five-f-widget-body {
  background-color: var(--five-f-widget-body-bg-color);
  border-bottom-left-radius: var(--five-f-widget-radius);
  border-bottom-right-radius: var(--five-f-widget-radius);
}

/**
 * Padded widget body.
 * Intended to be used inside or additionally to .five-f-widget-body.
 */
.five-f-widget-body-content {
  padding: 0.3rem 0.5rem 0.3rem 0.75rem;
}
