/**
   * Dashboard specific: Calendar & Due date panel
   */
// Unset the calendar header color.
.calendar .calendar-body .day {
  background-color: unset !important;
}

.calendar .weekday-header,
.calendar .weekday-header .weekday {
  background-color: mat-color($primary-paperbirdTheme, A400) !important;
}

dvtx-activities-overdue-events .upcoming-display {
  background-color: mat-color($primary-paperbirdTheme, A400) !important;
}
