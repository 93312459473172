dvtx-message-bell,
dvtx-page-header {
  mat-icon {
    color: $theme-curacon-default-icon-color;
  }
  .dvtx-search-header-navbar {
    &__input {
        color: black;
        background-color: $dvtx-fivef-blue-25;
        border: 2px solid $dvtx-fivef-blue-25;
        &::placeholder {
            color: color($dvtx-fivef-blue-25 a(0.8));
            letter-spacing: 1.5px;
        }
        &:hover,
        &:focus {
            border: 2px solid mat-color($primary-curaconTheme) !important;
            border-bottom: 2px solid mat-color($primary-curaconTheme) !important;
        }
    }
  }
  .dvtx-search-header-navbar-active {
    border: 2px solid mat-color($primary-curaconTheme) !important;
    border-bottom: 2px solid mat-color($primary-curaconTheme) !important;
  }
}
