/**
   * General Font Setup.
   *
   * $curacon-typography: mat-typography-config(
   *   $font-family: "Arial, sans-serif",
   * );
   * @include angular-material-typography($curacon-typography);
   */
*:not(mat-icon) {
  font-family: Arial, sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
}
