@import "lib/styles/utils/var";
@import "lib/styles/utils/mixins";
@import "lib/styles/utils/fonts_mixins";

.five-f-only-print {
  display: none !important;
}

@media print {
  body,
  mat-sidenav-content {
    background-color: white !important;
    font-family: Roboto Regular, "Roboto", sans-serif;
  }

  h1, h2, h3, h4, h5, p, div {
    font-family: Roboto Regular, "Roboto", sans-serif;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-label-wrapper,
  .mat-form-field-label {
    display: none;
  }

  .mat-hint {
    display: none;
  }

  .five-f-no-print {
    display: none !important;
  }

  .five-f-only-print {
    display: block !important;
  }

  .five-f-only-print-flex {
    display: flex !important;
  }
}
