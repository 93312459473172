.mat-list-item {

  &.mat-list-option {
    .mat-list-item-content {
      align-items: center!important;
      height: inherit !important;
      padding: $content-gap !important;

      .text-crop {
        max-width: 100%;
        @include text-crop();
      }
    }
  }

  &.mat-list-item-disabled {
    color: $dvtx-cool-grey-200;
  }
}
