/**
   * Upload & dropzone.
   */
.drag-n-drop__icon, .drag-n-drop__button {
  // color: white !important;
  background-color: mat-color($accent-coloredFiveFTheme, A100) !important;
}

.drag-n-drop__button {
  p {
    margin: 0px !important;
  }
}

.drag-n-drop:hover {
  background-color: mat-color($accent-coloredFiveFTheme, A700) !important;
  border: 1px solid mat-color($accent-coloredFiveFTheme, A700) !important;
  // color: white !important;
}

.five-f-file-attachment-button-container.five-f-file-upload-button:hover {
  background-color: mat-color($accent-coloredFiveFTheme, A700) !important;
  border: 1px solid mat-color($accent-coloredFiveFTheme, A700) !important;
  color: white !important;
}

dvtx-csv-import-drop-zone {
  background-color: mat-color($accent-coloredFiveFTheme, A100) !important;
  border: 1px dashed mat-color($accent-coloredFiveFTheme, A100) !important;
  padding: 0px;

  .drag-n-drop__button .mat-button:hover {
    background-color: mat-color($accent-coloredFiveFTheme, A700) !important;
    color: white !important;
  }
}

