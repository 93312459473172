/**
   * Mat Checkbox
   */
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  border: 1px solid mat-color($accent-coloredFiveFTheme, A700) !important;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  border-color: mat-color($accent-coloredFiveFTheme, A700);
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  border-color: mat-color($accent-coloredFiveFTheme, A700) !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  border: 1px solid mat-color($accent-coloredFiveFTheme, A700) !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: mat-color($accent-coloredFiveFTheme, A400);
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: mat-color($accent-coloredFiveFTheme, A700) !important;
}

.dvtx-contact-selection-menu .mat-pseudo-checkbox-checked,
.dvtx-contact-selection-menu .mat-pseudo-checkbox-indeterminate,
.dvtx-contact-selection-menu .mat-accent .mat-pseudo-checkbox-checked,
.dvtx-contact-selection-menu .mat-accent .mat-pseudo-checkbox-indeterminate,
.dvtx-global-options .mat-pseudo-checkbox-checked,
.dvtx-global-options .mat-pseudo-checkbox-indeterminate,
.dvtx-global-options .mat-accent .mat-pseudo-checkbox-checked,
.dvtx-global-options .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: mat-color($accent-coloredFiveFTheme, A700) !important;
}
