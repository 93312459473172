$primary-grantThornton: (
  50:  rgb(114,87,153),
  100: rgb(114,87,153),
  200:  rgb(114,87,153),
  300:  rgb(114,87,153),
  400:  rgb(114,87,153),
  500:  rgb(114,87,153),
  600:  rgb(114,87,153),
  700:  rgb(114,87,153),
  800: rgb(114,87,153),
  900:  rgb(114,87,153),
  A100:  rgb(220,213,229),
  A200:  rgb(185,171,204),
  A400:  rgb(149,129,178),
  A700: rgb(114,87,153),
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);
$accent-grantThornton: (
  50:   rgb(51,185,196),
  100: rgb(51,185,196),
  200:   rgb(51,185,196),
  300:  rgb(51,185,196),
  400:  rgb(51,185,196),
  500:   rgb(51,185,196),
  600:   rgb(51,185,196),
  700:   rgb(51,185,196),
  800:  rgb(51,185,196),
  900:   rgb(51,185,196),
  A100:  rgb(204,237,240),
  A200:  rgb(153,220,225),
  A400:   rgb(102,202,211),
  A700:  rgb(51,185,196),
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$primary-grantThorntonTheme: mat-palette($primary-grantThornton);
$accent-grantThorntonTheme: mat-palette($accent-grantThornton, A200, A100, A400);
$theme-grantThornton: mat-light-theme($primary-grantThorntonTheme, $accent-grantThorntonTheme);
$background-color: rgb(229, 225, 221);
// $theme-link-hover-color: #de002e;
$theme-link-hover-color: rgb(155, 215, 50);
$dvtx-action-blue: mat-palette($primary-grantThornton);
$background: map-get($theme-grantThornton, background);
$background: map_merge($background, (background: $background-color));
$theme-grantThornton: map_merge($theme-grantThornton, (background: $background));
$theme-grantThornton-font-color: rgb(56, 70, 88);
$theme-grantThornton-default-icon-color: $theme-grantThornton-font-color;
$theme-grantThornton-gray: #74767a;
$theme-grantThornton-light-gray: rgb(229, 225, 221);

@include angular-material-theme($theme-grantThornton);
