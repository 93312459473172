// FORMS BASIC
// Text inputs
input,
textarea,
select {
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 300px white inset;
    transition: none;
  }
}

input[type=text],
input[type=password],
input[type=email],
input[type=url],
input[type=time],
input[type=date],
input[type=datetime-local],
input[type=tel],
input[type=number],
input[type=search-md],
input[type=search],
textarea.md-textarea {
    // General Styles
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $input-border-color;
    border-radius: 0;
    outline: none;
    // height: 2.1rem;
    width: 100%;
    font-size: 1rem;
    box-shadow: none;
    @include box-sizing(content-box);
    transition: all .3s;
    text-overflow:ellipsis;
    // Disabled input style
    &:disabled,
    &:disabled,
    &[readonly="readonly"] {
        color: $input-disabled-color;
        border-bottom: 1px dotted $input-disabled-color;
        background-color: transparent;
    }
    // Disabled label style
    &:disabled+label,
    &[readonly="readonly"]+label {
        color: $input-disabled-color;
        background-color: transparent;
    }
    // Focused input style
    &:focus:not([readonly]) {
        border-bottom: 1px solid $input-focus-color;
        /* box-shadow: 0 1px 0 0 $input-focus-color; */
    }
    // Focused label style
    &:focus:not([readonly])+label {
        color: $input-focus-color;
    }
    // Valid Input Style
    &.valid{
        border-bottom: 1px solid $input-success-color;
        /* box-shadow: 0 1px 0 0 $input-success-color; */
    }
    &:focus.valid{
        border-bottom: 1px solid $input-focus-color;
    }
    // Custom Success Message
    &.valid+label:after,
    &:focus.valid+label:after {
        content: attr(data-success);
        color: $input-success-color;
        opacity: 1;
    }
    // Invalid Input Style
    &.invalid,
    &:focus.invalid {
        border-bottom: 1px solid $input-error-color;
        /* box-shadow: 0 1px 0 0 $input-error-color; */
        + label{
            color:$dvtx-danger;
        }
    }
    // Custom Error message
    &.invalid+label:after,
    &:focus.invalid+label:after {
        content: attr(data-error);
        color: $input-error-color;
        opacity: 1;
    }
    // Form Message Shared Styles
    &+label:after {
        display: block;
        content: "";
        position: absolute;
        top: 65px;
        opacity: 0;
        transition: .2s opacity ease-out, .2s color ease-out;
    }
    &.input-alternate {
        padding: 0 15px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12);
        font-size: 0.875rem;
        border-bottom: 0;
        transition: none !important;
        &:hover,
        &:focus {
            box-shadow: 0 3px 8px 0 rgba(0, 0, 0, .2), 0 0 0 1px rgba(0, 0, 0, .08) !important;
            border-bottom: 0;
        }
    }
}

label {
    font-size: $label-font-size;
    color: darken ($input-border-color, 90%);
}

.form-control {
    padding: 0;
    padding-bottom: 0.2rem;
    padding-top: 0.6rem;
    font-size: 1rem;
    line-height: 1.5;
    background-color: transparent;
    background-image: none;
    border-radius: 0;
    margin-top: 0.2rem;
    margin-bottom: 1rem;
    color:$dvtx-cool-grey-400;
    &:focus {
        background: transparent;
        color:$dvtx-cool-grey-500;
    }
}

.form-control:disabled,
.form-control[readonly] {
    background-color: transparent;
    border-bottom: 1px solid #e0e0e0;
}

// Styling for input field wrapper
.md-form {
    position: relative;
    margin-bottom: 1.9rem;
    &.form-sm {
        input {
            padding-bottom: 0.2rem;
            padding-top: 0.2rem;
            font-size: 0.8rem;
            line-height: 0.5;
        }
        label {
            font-size: 0.9rem;
        }
        .prefix {
            font-size: 1.5rem;
            top: 0.4rem;
        }
        .prefix~input,
        .prefix~textarea {
            margin-left: 2.2rem; //Po dodaniu marginesu z lewej powstaje dziwny margines po prawej. Do poprawienia przed updatem
        }
        .prefix~label {
            margin-left: 2.2rem;
        }
    }
    .btn {
        margin-bottom: 1.5rem;
    }
    label {
        color: $dvtx-fir-green-100;
        position: absolute;
        top: 0.9rem;
        left: 0;
        font-size: 1rem;
        cursor: text;
        @include transition(.2s ease-out);
        white-space: nowrap;
        width:100%;
        overflow:hidden;
        text-overflow:ellipsis;
        @media #{$medium-and-down}{
            line-height:1rem;
            top:1.2rem;
        }
    }
    label.active {
        font-size: $label-font-size;
        @include transform(translateY(-140%));
    }
    // Prefix Icons
    .prefix {
        position: absolute;
        width: 3rem;
        font-size: 2rem;
        @include transition(color .2s);
        &.active {
            color: $input-focus-color;
        }
    }
    .prefix~input,
    .prefix~textarea {
        margin-left: 3rem;
        width: 92%;
        width: calc(100% - 3rem);
    }
    .prefix~textarea {
        padding-top: .8rem;
    }
    .prefix~label {
        margin-left: 3rem;
    }
    @media #{$medium-and-down} {
        .prefix~input {
            width: 86%;
            width: calc(100% - 3rem);
        }
    }
    @media #{$small-and-down} {
        .prefix~input {
            width: 80%;
            width: calc(100% - 3rem);
        }
    }
    &.input-group {
        .form-control {
            margin: 0;
            padding-left: 1rem;
            @include placeholder {
                color: #999;
                padding-top: 2px;
            }
        }
    }
}

/* right label text */
.right-label{
    margin-bottom:39px;
    display:block;
    margin-left:-15px;
    color:$dvtx-fir-green-100;
    position:relative;
    @include mq-md-down{
        margin-bottom:35px;
    }
}

.datepicker-md-form {
    position:relative;
     &:before{
        content:'';
        font-family:FontAwesome;
        clear:both;
        position:absolute;
        color:$dvtx-fir-green-100;
        bottom:7px;
        left:4px;
        font-size:18px;
    }

    input{
            width:calc(100% - 40px);
            padding-left:25px;
            padding-right:15px;
    }
}

.datepicker-md-form{
    input.datepicker{
        border-bottom-color: $dvtx-fir-green-100;
        @media #{$medium-and-down}{
            padding-top:9px;
            padding-bottom:3px;
        }
    }
    &:before{
        content:'\f133';
    }
    &:after{
        content:"";
        clear:both;
        position:absolute;
        bottom:19px;
        right:9px;
        width:7px;
        height:7px;
        transform:rotate(-45deg);
        border-style: solid;
        border-width:0 0 1px 1px;
        border-color: transparent transparent $dvtx-fir-green-100 $dvtx-fir-green-100;
    }
}

.search-md-form {
  position: relative;
  color: map_get($dvtx-color-palette, cool-grey-400);
  &:after {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    z-index: 1;
    transform: translateY(-50%);
    font-family: FontAwesome;
    content: "\f002";
    color: inherit;
  }
}

.form-inline {
    fieldset {
        margin-right: 1.5rem;
    }
}

// Default textarea
textarea {
    width: 100%;
    height: 3rem;
    background-color: transparent;
    vertical-align:top;
    &.md-textarea {
        /* overflow-y: hidden; */
        /* prevents scroll bar flash */
        padding: 1.6rem 0;
        /* prevents text jump on Enter keypress */
        /* resize: none; */
        min-height: 3rem;
        color:$dvtx-cool-grey-400;
        &:focus {
            background: transparent;
            color:$dvtx-cool-grey-500;
        }
        &.sm-textarea{
            min-height:2rem;
            padding:0.9rem 0 0;
        }
    }
}

// For textarea autoresize
.hiddendiv {
    display: none;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    /* future version of deprecated 'word-wrap' */
    padding-top: 1.2rem;
    /* prevents text jump on Enter keypress */
}

//Input color
.input-dark-bg {
    // Style Placeholders
    @include placeholder {
        color: #fff!important;
        font-weight: 300;
    }
    input[type=text] {
        border-bottom: 1px solid #fff;
    }
    .form-control {
        color: #fff;
    }
}

.input-light-bg {
    // Style Placeholders
    @include placeholder {
        color: #1C2331!important;
        font-weight: 300;
    }
    input[type=text] {
        border-bottom: 1px solid #1C2331;
    }
    .form-control {
        color: #1C2331;
    }
}

.form-inline .form-group {
    margin-right: 2rem;
}

i {
    &.btn-primary {
        background: $primary-color!important;
    }
    &.btn-default {
        background: $default-color!important;
    }
    &.btn-success {
        background: $success-color!important;
    }
    &.btn-info {
        background: $info-color!important;
    }
    &.btn-warning {
        background: $warning-color-dark!important;
    }
    &.btn-danger {
        background: $danger-color-dark!important;
    }
}
