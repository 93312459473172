.five-f-mat-input {
  .mat-input-element {
    padding: 0 !important;
  }

  .five-f-mat-input-disabled {
    .mat-form-field-label {
      color: $dvtx-fivef-blue-100 !important;
    }
  }

  .mat-select .mat-select-value {
    padding: 0;
  }

  .mat-form-field {
    &-infix {
      border-top: 0;
    }
    &-flex {
      align-items: center;
      .mat-form-field-prefix .mat-icon {
        margin: 0;
      }
    }
  }

  &.five-f-search {
    width: 100%;
    max-width: 300px;
  }

  .five-f-search {
    width: 100%;
    max-width: 300px;
    flex-grow: 1;
    min-width: 250px;
  }

  /**
   * Styling for icon form input layouts.
   * See CreateEmployeeDialogComponent for an example of usage
   * (employee creation dialog inside employee table view).
   * Refactoring from CreateEmployeeDialogComponent,
   * MembershipInvitationComponent.
   */
  &.five-f-icon-content-layout,
  .five-f-icon-content-layout {
    display: flex;
    margin-bottom: 1rem;

    & > .mat-icon {
      margin-right: -36px;
      width: 36px;
    }

    .row {
      width: 100%;
      padding-left: 36px;
      [class^=col-] {
        box-sizing: border-box;
      }
    }

    & > mat-icon {
      &:not(:empty) {
        margin-top: 10px;
      }

      flex: 0 0 auto;
      color: $dvtx-fivef-font-gray-40;
      margin-right: 10px;
    }

    > .five-f-icon-content-layout--content {
      flex: 1 1 0px;
      min-width: 0;
    }
  }
}
