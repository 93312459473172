.five-f-mat-stepper {
  .mat-stepper-horizontal, .mat-stepper-vertical {
    background-color: $dvtx-app-bg;
  }

  .mat-horizontal-stepper-header-container {
    background: $dvtx-app-bg;
  }

  .mat-horizontal-content-container {
    overflow: visible;
  }

  .mat-stepper-horizontal-line {
    height: 1px;
  }

  .mat-horizontal-stepper-header .mat-step-icon, .mat-horizontal-stepper-header .mat-step-icon-not-touched {
    align-items: center;
    justify-content: center;
    display: flex;
  }
}
