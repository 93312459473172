/**
   * Dashboard specific: Calendar & Due date panel
   */
// Dashboard: Calendar & Due date panel
.calendar .calendar-body .day {
  background-color: unset !important;
}

.calendar .weekday-header,
.calendar .weekday-header .weekday {
  background-color: mat-color($primary-curaconTheme, A400) !important;
}

dvtx-activities-overdue-events .upcoming-display {
  background-color: mat-color($primary-curaconTheme, A400) !important;
}
