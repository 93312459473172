/**
 * Boxes used for settings overviews/small panels.
 */
.listing-box__box-header {
  background-color: $theme-curacon-font-color !important;
}

.listing-box__box-header:hover {
  background-color: mat-color($primary-curaconTheme, A400) !important;
}

dvtx-simple-dashboard-panel.disabled {
  display: none !important;
}
