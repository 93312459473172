// 176	204	237	250
// 219	251	249	249
// 191	220	241	247
// $value: rgb(80, 175, 113)

$primary-coloredFiveF: (
  50:  rgb(176, 219, 191),
  100: rgb(176, 219, 191),
  200:  rgb(176, 219, 191),
  300:  rgb(176, 219, 191),
  400:  rgb(133, 199, 156),
  500:  rgb(133, 199, 156),
  600:  rgb(133, 199, 156),
  700:  rgb(80, 175, 113),
  800: rgb(80, 175, 113),
  900:  rgb(80, 175, 113),
  A100:  rgb(133, 199, 156),
  A200:  rgb(133, 199, 156),
  A400:  rgb(80, 175, 113),
  A700: rgb(80, 175, 113),
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

// B0	B1	B2	B3
//32	50	63	99
//67	106	136	181
//95	150	195	246
$accent-coloredFiveF: (
  50:   rgb(76, 163, 230),
  100: rgb(76, 163, 230),
  200:   rgb(76, 163, 230),
  300:  rgb(76, 163, 230),
  400:  rgb(76, 163, 230),
  500:   rgb(76, 163, 230),
  600:   rgb(76, 163, 230),
  700:   rgb(76, 163, 230),
  800:  rgb(76, 163, 230),
  900:   rgb(76, 163, 230),
  A100:  rgb(76, 163, 230),
  A200: rgb(76, 163, 230),
  A400:   rgb(50,106,150),
  A700:  rgb(32,67,95),
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$primary-coloredFiveFTheme: mat-palette($primary-coloredFiveF);
$accent-coloredFiveFTheme: mat-palette($accent-coloredFiveF, A200, A100, A400);
$theme-coloredFiveF: mat-light-theme($primary-coloredFiveFTheme, $accent-coloredFiveFTheme);
$background-color: rgb(229, 225, 221);
// $theme-link-hover-color: #de002e;
$theme-link-hover-color: rgb(80, 175, 113);
$dvtx-action-blue: mat-palette($primary-coloredFiveF);
$background: map-get($theme-coloredFiveF, background);
$background: map_merge($background, (background: $background-color));
$theme-coloredFiveF: map_merge($theme-coloredFiveF, (background: $background));
$theme-coloredFiveF-font-color: rgb(56, 70, 88);
$theme-coloredFiveF-default-icon-color: $theme-coloredFiveF-font-color;
$theme-coloredFiveF-gray: #74767a;
$theme-coloredFiveF-light-gray: rgb(229, 225, 221);

@include angular-material-theme($theme-coloredFiveF);
