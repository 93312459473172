/**
   * Collecto overrides.
   */
dvtx-truncated-description .show--less--link,
dvtx-truncated-description .show--more--link {
  color: mat-color($primary-coloredFiveFTheme) !important;
}

.collector-create-from .drag-n-drop:hover {
  .drag-n-drop__icon, .drag-n-drop__button {
    background-color: mat-color($accent-coloredFiveFTheme, A700) !important;
  }
}

.collector-create-from .textarea__input:focus {
  border-color: mat-color($primary-coloredFiveFTheme) !important;
}
