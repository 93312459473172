/**
   * Material and FiveF Card layout.
   */
.mat-card .mat-card-header .mat-card-title {
  background-color: unset !important;
}

.five-f-card .mat-card-header .mat-card-header-text .mat-card-title {
  background: unset !important;
}

.mat-card-title, .mat-tabs .mat-tab-header {
  background: mat-color($primary-grantThorntonTheme) !important;
}

.five-f-card.five-f-card-blue-header .mat-card-header .mat-card-header-text {
  background-color: mat-color($primary-grantThorntonTheme) !important;
}
