@import "src/lib/styles/utils/var";

:root {
  // Body
  --five-f-bg-color: #{$dvtx-cool-grey-25};

  // Main font colors
  --five-f-color: #{$dvtx-fivef-blue-500};

  // Main action color
  --five-f-action-color: #{$dvtx-action-blue};

  // a-tag
  --five-f-link-color: #{$dvtx-action-blue};
  --five-f-link-color-hover: #{$dvtx-action-blue};

  // Font color for disabled state
  --five-f-color-disabled: #{$dvtx-fivef-blue-200};

  // Font color for disabled state
  --five-f-hr: #{$dvtx-fivef-blue-50};

  // Disabled color for background and border.
  --five-f-border-color-disabled: #{$dvtx-fivef-blue-50};
  --five-f-background-color-disabled: #{$dvtx-fivef-blue-50};

  // Indicator badges at workflows and dashboard.
  --five-f-indicator-color: #{$dvtx-fivef-blue-500};
  --five-f-indicator-background-color: #{$dvtx-fivef-blue-100};
  --five-f-indicator-color-unread: white;
  --five-f-indicator-background-color-unread: #{$dvtx-action-blue};

  // Filter default border
  --five-f-filter-border-color: #{$dvtx-fivef-blue-500};
  --five-f-filter-border-color-hover: #{$dvtx-fivef-blue-500};

  // Filter button default colors
  --five-f-filter-button-color-active: white;
  --five-f-filter-button-background-color-active: #{$dvtx-fivef-blue-500};

  // Beta label tags.
  --five-f-filter-beta-tag-color: white;
  --five-f-filter-beta-tag-background-color: #{$dvtx-fivef-blue-500};

  // Badge of filters (showing count)
  --five-f-filter-badge-background-color: #{$dvtx-fivef-blue-500};

  // Dashboard dropdown menu: Top border and hook.
  --five-f-dropdown-border-top-color: #{$dvtx-fivef-blue-500};

  // Default process icon color.
  --five-f-process-icon-color: #{$dvtx-fivef-blue-400};

  // Status colors
  --five-f-status-overdue: #{$dvtx-danger};

  // Button colors
  --five-f-icon-button-color: #{$dvtx-fivef-blue-300};
  --five-f-icon-button-color-hover: #{$dvtx-action-blue};

  // Inputs
  // ce: content editable fields.
  --five-f-input-ce-bg-color-hover: #{$dvtx-fivef-blue-50};

  // Panels
  //--five-f-widget-header-bg-color: #{$dvtx-fivef-blue-25};
  //--five-f-widget-body-bg-color: #{$dvtx-fivef-blue-25};
  --five-f-widget-header-bg-color: white;
  --five-f-widget-body-bg-color: white;
  --five-f-widget-radius: 7px;

  .five-f-widget {
    border-top-left-radius: var(--five-f-widget-radius);
    border-top-right-radius: var(--five-f-widget-radius);
    border-bottom-left-radius: var(--five-f-widget-radius);
    border-bottom-right-radius: var(--five-f-widget-radius);
  }

  .five-f-widget-header {
    background-color: var(--five-f-widget-header-bg-color);
    border-top-left-radius: var(--five-f-widget-radius);
    border-top-right-radius: var(--five-f-widget-radius);
    padding: 0.3rem 0.5rem 0.3rem 0.75rem;
  }

  .five-f-widget-body {
    background-color: var(--five-f-widget-body-bg-color);
    border-bottom-left-radius: var(--five-f-widget-radius);
    border-bottom-right-radius: var(--five-f-widget-radius);
  }

  .five-f-widget-body-content {
    padding: 0.3rem 0.5rem 0.3rem 0.75rem;
  }
}
