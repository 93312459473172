.five-f-mat-tab, .five-f-mat-button-toggle-group {
  mat-button-toggle {
    margin-right: 15px;
    padding: 0 !important;
    min-width: 0 !important;
    height: 48px;
    cursor: pointer;
    box-sizing: border-box;
    min-width: 160px;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    vertical-align: top;
    text-decoration: none;
    position: relative;
    overflow: hidden;
    -webkit-tap-highlight-color: transparent;
  }
  a.mat-tab-link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $dvtx-fivef-blue-300;
    margin-left: 15px;
    margin-right: 15px;
    padding: 0 !important;
    min-width: 0 !important;
    &:first-child {
      margin-left: 0;
    }

    .mat-icon {
      margin-right: 10px;
    }

    &:active, &:visited, &:focus {
      color: $dvtx-fivef-blue-500;
    }
  }

  .mat-tab-nav-bar {
    padding: 0 15px;
    border-bottom: 0;
  }

  .mat-tab-nav-bar.mat-primary {
    background-color: transparent;
  }

  &.mat-tab-nav-bar.mat-primary,
  &.mat-tab-nav-bar.mat-primary {
    background-color: transparent !important;
  }

  .mat-tab-group ::ng-deep .mat-tab-header {
    background-color: transparent !important;
    padding-bottom: 0;
  }

  &.mat-tab-group ::ng-deep .mat-tab-header,
  &.mat-tab-group ::ng-deep .mat-tab-header {
    background-color: transparent !important;
  }

  .mat-tab-group .mat-tab-header {
    background-color: transparent !important;
    padding-bottom: 0;

    .mat-tab-label {
      padding: 0;
      margin-left: 15px;
      margin-right: 15px;

      &:first-child {
        margin-left: 0;
      }
    }

    &.mat-tab-group .mat-tab-header,
    &.mat-tab-group .mat-tab-header {
      background-color: transparent !important;
    }

    .mat-ink-bar {
      background-color: $dvtx-fivef-blue-500; // $dvtx-action;
    }
  }

  .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $dvtx-fivef-blue-500; // $dvtx-action;
  }
  .mat-tab-label,
  .mat-tab-link {
    &.cdk-program-focused {
      &:not(.mat-tab-disabled) {
        background-color:transparent !important;
      }
    }
  }
}
