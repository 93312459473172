@import "../../../utils/var";
@import "../../../utils/mixins";

.mat-radio-group {
  display: flex;
  flex-wrap: wrap;

  .mat-radio-button {
    margin: 0 5px 0 0;

    .mat-radio-outer-circle {
      border-color: $dvtx-cool-grey-100;
      border-width: 1px!important;
    }
  }

  .mat-radio-label {
    font-size: 1rem;

    .mat-radio-label-content {
      width: 100%;
      @include text-crop;
    }
  }
}

.full-named {
  .mat-radio-group {
    .mat-radio-label {
      .mat-radio-label-content {
        @include mq-sm-up {
          max-width: initial;
        }
      }
    }
  }
}

.mat-radio-button {

  &.mat-accent {
    .mat-radio-inner-circle {
      background-color: $dvtx-action;
      .mat-radio-checked & {
        border-color: $dvtx-action;
      }
    }

    .mat-radio-label {
      display: flex;
      align-items: center;
      &-content {
        align-items: center;
        display: flex;
        .mat-icon {
          margin-right: 0.5rem;
        }
      }
    }
  }
}
