$primary-paperbird: (
  50:  #e4732b,
  100: #e4732b,
  200:  #e4732b,
  300:  #e4732b,
  400:  #e4732b,
  500:  #e4732b,
  600:  #e4732b,
  700:  #e4732b,
  800: #e4732b,
  900:  #e4732b,
  A100:  #e4732b,
  A200:  #e4732b,
  A400:  #ffa359,
  A700: #e4732b,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);
$accent-paperbird: (
  50:   rgb(51,185,196),
  100: rgb(51,185,196),
  200:   rgb(51,185,196),
  300:  rgb(51,185,196),
  400:  rgb(51,185,196),
  500:   rgb(51,185,196),
  600:   rgb(51,185,196),
  700:   rgb(51,185,196),
  800:  rgb(51,185,196),
  900:   rgb(51,185,196),
  A100:  rgb(204,237,240),
  A200:  rgb(153,220,225),
  A400:   rgb(102,202,211),
  A700:  rgb(51,185,196),
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$primary-paperbirdTheme: mat-palette($primary-paperbird);
$accent-paperbirdTheme: mat-palette($accent-paperbird, A200, A100, A400);
$theme-paperbird: mat-light-theme($primary-paperbirdTheme, $accent-paperbirdTheme);
$background-color: rgb(229, 225, 221);
// $theme-link-hover-color: #de002e;
$theme-link-hover-color: rgb(155, 215, 50);
$dvtx-action-blue: mat-palette($primary-paperbird);
$background: map-get($theme-paperbird, background);
$background: map_merge($background, (background: $background-color));
$theme-paperbird: map_merge($theme-paperbird, (background: $background));
$theme-paperbird-font-color: rgb(56, 70, 88);
$theme-paperbird-default-icon-color: $theme-paperbird-font-color;
$theme-paperbird-gray: #74767a;
$theme-paperbird-light-gray: rgb(229, 225, 221);

@include angular-material-theme($theme-paperbird);
