/**
   * Dropdown overrides
   */
.five-f-dropdown-menu-items-toggler.mat-primary {
  background: mat-color($primary-curaconTheme) !important;
  color: white !important;
}

.five-f-dropdown-menu-items,
.five-f-color-picker-dropdown {
  border-top: 4px solid $theme-curacon-font-color !important;
}

.five-f-dropdown-menu-items-container.menu-opened:after {
  border-bottom: 6px solid $theme-curacon-font-color !important;
}

.five-f-dropdown-menu-items li button:hover,
.five-f-dropdown-menu-items li .mat-button:hover {
  color: mat-color($primary-curaconTheme) !important;
}

.five-f-dropdown-content.mat-menu-above .mat-menu-content,
.five-f-dropdown-content.mat-menu-below .mat-menu-content {
  border-bottom: 4px solid $theme-curacon-font-color !important;
}

li.nav-item.dropdown .dropdown-menu > li.dropdown-item-wrapper .dropdown-item:hover,
li.nav-item.dropdown .dropdown-menu > li.dropdown-item-wrapper .dropdown-item:active {
  background-color: mat-color($primary-curaconTheme) !important;
  color: white !important;
}

.dropdown .dropdown-menu .dropdown-item:hover,
.dropdown .dropdown-menu .dropdown-item:active {
  background-color: mat-color($primary-curaconTheme) !important;
  color: white !important;
}

.list-item mat-icon:not(.document__icon):hover {
  color: mat-color($primary-curaconTheme) !important;
}

.five-f-dropdown-content.mat-menu-below .mat-menu-content {
  border-top: 4px solid $theme-curacon-font-color !important;
}

.mat-menu-item:hover:not([disabled]):not(.simple-confirm),
.curaconTheme .mat-menu-item.cdk-program-focused:not([disabled]):not(.simple-confirm),
.curaconTheme .mat-menu-item.cdk-keyboard-focused:not([disabled]):not(.simple-confirm),
.curaconTheme .mat-menu-item-highlighted:not([disabled]):not(.simple-confirm) {
  background-color: mat-color($primary-curaconTheme) !important;
  color: white !important;

  .mat-icon, sub {
    color: white !important;
  }
}
