@import "../../../utils/var";
@import "../../../utils/mixins";

/* Problematic overrides
 * Check
 * - Registration form is _with_ overrides displaced in mat-error
 * - Registration wizard is _with_ overrides displaced in mat-error
 * - Organization creation form is _without_ these overrides unusable
 */
.mat-form-field {
  display: block!important;
  line-height: 1.5;
  width: 100%;
}
.mat-form-field-infix {
  padding: 0;
}

.mat-input-element {
  font-size: 16px!important;
  padding: 10px 0px!important;
  margin: -11px 0px;
}

.mat-input-underline .mat-input-ripple {
  height: 1px!important;
}

.mat-form-field-flex {
  .mat-input-prefix {
    position: relative;
    bottom: 5px;
    padding-right: 5px;
  }
}

.mat-input-underline {
  background-color: $dvtx-fivef-blue-300;
}

.header-select {
  .mat-input-underline {
    background-color: $dvtx-white;
  }
}

.mat-input-underline .mat-input-ripple {
  background-color: $dvtx-action;
}

.mat-input-placeholder {
  color: $dvtx-fivef-blue-300;
}

.mat-focused .mat-input-placeholder {
  color: $dvtx-action;
}

/*sec-input*/
.sec-input.mat-focused .mat-input-placeholder {
  color: $dvtx-success!important;
}
.sec-input .mat-input-underline .mat-input-ripple {
  background-color: $dvtx-success!important;
}

.dvtx-registration-wizard-input-override,
.dvtx-cac-materiality-form,
.dvtx-terms-and-conditions,
.dvtx-cac-appendix-respond-table,
.dvtx-client-creation-form,
.dvtx-client-table,
.dvtx-cac-create-society-dialog,
.dvtx-cac-recipient-selection,
.dvtx-society-selection-contact-form,
.dvtx-new-project-form,
.dvtx-cms-article-editor,
.dvtx-cms-site-settings,
.dvtx-motd-editor,
.search--task--feild,
.dvtx-operator-organization-details,
.dvtx-external-access-creation-dialog,
.dvtx-cav-external-access-link-dialog,
.five-f-lock-folder-dialog,
.five-f-partner-link-creation-dialog,
.dvtx-third-party-wizard,
dvtx-subarea-verifier-request-check,
dvtx-subarea-verifier-request-import,
dvtx-audit-contact-create-item,
dvtx-timeline-settings-general,
dvtx-registration-setup,
dvtx-participant-role-dialog,
dvtx-recipient-autocomplete,
dvtx-add-and-invite,
dvtx-document-signature-user-account-form,
dvtx-project-overview,
.five-f-mat-form-dialog,
.dvtx-document-signature-user-account-dialog,
.dvtx-document-signature-recipient-selector__dialog,
.dvtx-document-listing-groups-container__search,
.dvtx-third-party__contact-verification-form,
.dvtx-third-party__external-view,
.five-f-add-and-invite,
.five-f-fast-docs-create-partner-link-dialog,
five-f-signature-preview-navigation,
.dvtx-bookman-admin-panel,
.five-f-faq,
.dvtx-mat-input-form-override {
  .mat-form-field {
    display: block!important;
    line-height: 1.4;
  }

  .mat-input-element {
    font-size: 14px!important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .mat-select-trigger {
    width: 100%;
    padding: 0;
    height: auto !important;
    min-width: 1px;
  }

  .mat-select-value {
    padding: 0;
  }
}

.five-f-mat-form-dialog,
.five-f-sign__tan-form,
.dvtx-mat-input-form-override {
  .mat-form-field-flex > .mat-form-field-infix {
    padding: 0.2rem 0 !important;
  }

  .mat-form-field-label-wrapper { top: -1.5em; }

  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.1em) scale(.75);
    width: 133.33333%;
  }

  .mat-input-element {
    font-size: 16px !important;
    padding: 0 !important;
    margin: 0;
  }

  ._mat-form-field-appearance-outline .mat-form-field-outline {
    color: #db0000;
  }

  /* focused border */
  .mat-focused.mat-form-field-appearance-outline .mat-form-field-outline {
    color: #db0000;
    background-color: white;
  }

  /* hovered border */
  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: #db0000;
    background-color: white;
  }
}

.dvtx-document-signature-recipient-selector__dialog,
.dvtx-preparation-of-confirmation__third-party-type,
.dvtx-mat-input-form-override {
  .mat-radio-group .mat-radio-label .mat-radio-label-content {
    width: 100%;
    max-width: 100%;
    overflow: visible;
  }
}

.five-f-beta-feature-button-tag {
  position: absolute;
  bottom: -1px;
  right: -3px;
  opacity: 0.9;
  color: var(--five-f-filter-beta-tag-color);
  background-color: var(--five-f-filter-beta-tag-background-color);
  padding: 1px 2px;
  border-radius: 3px;
  border-collapse: collapse;
  font-size: 9px;
  box-shadow: 0 4px 4px -4px #c9c9c9 !important;
}
