/**
 * Boxes used for settings overviews/small panels.
 */
.listing-box__box-header {
  background-color: $theme-coloredFiveF-default-icon-color !important;
}

.listing-box__box-header:hover {
  background-color: mat-color($primary-coloredFiveFTheme, A400) !important;
}

// hide box if disabled
//dvtx-simple-dashboard-panel.disabled {
//  display: none !important;
//}
