.coloredFiveFTheme {
  // Generic CSS color setup. See base/_colors for all
  // existing CSS colors variables.
  --five-f-action-color: rgb(80, 175, 113);
  --five-f-indicator-background-color-unread: rgb(80, 175, 113);

  --five-f-filter-beta-tag-background-color: rgb(80, 175, 113);
  --five-f-filter-badge-background-color: rgb(80, 175, 113);

  --five-f-filter-button-background-color-active: rgb(80, 175, 113);

  // Button color hover override
  --five-f-icon-button-color-hover: rgb(80, 175, 113);

  // General overrides (order important)
  // 1. Base overrides
  // 2. Functionality overrides
  // 3. Module dependent overrides.
  @import "colored-five-f/colors";
  @import "colored-five-f/font";
  @import "colored-five-f/avatar";
  @import "colored-five-f/atag_link_nav";
  @import "colored-five-f/button";
  @import "colored-five-f/card";
  @import "colored-five-f/checkbox";
  @import "colored-five-f/dialog";
  @import "colored-five-f/progress_bar";
  @import "colored-five-f/radio_button";
  @import "colored-five-f/table";
  @import "colored-five-f/tabs";
  @import "colored-five-f/stepper";

  // Special functionalities
  @import "colored-five-f/alert";
  @import "colored-five-f/dms_selection_folder_tree";
  @import "colored-five-f/dropdown_menu";
  @import "colored-five-f/listing_box";
  @import "colored-five-f/tags_labels";
  @import "colored-five-f/person-selector";
  @import "colored-five-f/upload";
  @import "colored-five-f/sidebar";

  // View/Module overrides
  @import "colored-five-f/collecto";
  @import "colored-five-f/signature";
  @import "colored-five-f/dashboard_calendar";
  @import "colored-five-f/page-header";
}
