.five-f-file-attachment-button-container {
  padding: 0.5rem 0.5rem !important;
  margin: 0 0 !important;
  height: 100%;
  width: 100%;

  border: 1px solid $dvtx-fivef-blue-100;
  border-left: 6px solid $dvtx-fivef-blue-100;
  // border-bottom: 1px solid $dvtx-fivef-blue-100;
  min-width: 180px;
  cursor: pointer;
  min-height: 50px;
  margin-bottom: 3px;
  padding-top: 3px;

  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;

  a {
    color: $dvtx-fivef-blue-500;

    &:hover {
      color: $dvtx-action-blue;
    }
  }

  &:hover {
    color: $dvtx-action-blue;
    background-color: $dvtx-fivef-skyblue-25;
    border-left: 6px solid $dvtx-action-blue;
    //border-bottom: 1px solid $dvtx-action-blue;
  }

  &.dvtx-border-color {
    &.dvtx-border-color {
      border-left-width: 6px;
      border-left-style: solid;
    }

    &-green {
      border-left: 6px solid #00CA5C;
    }
  }

  .details--part {
    width: calc(100% - 87px);
    .details {
      width: calc(100% - 24px);
      .h4 {
        width: 100%;
        overflow: hidden;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 8px;
      }
    }
  }

  &.five-f-public-download-button {
    background: $dvtx-blue-50;
    border: none !important;
    margin-bottom: 0;
    width: 100%;
    height: 100%;
  }

  &.dvtx-border-color {
    &-five-f {
      border: 1px solid transparent !important;
      background-color: white !important;
      box-shadow: 0 4px 4px -4px #c9c9c9 !important;
      border-collapse: collapse;
    }

    &-five-f:hover {
      border: 1px solid $dvtx-action-blue !important;
      box-shadow: 0 12px 12px -12px #c9c9c9 !important;
    }
  }

  &.five-f-file-upload-button {
    display: inline-block;
    color: 6px solid $dvtx-fivef-blue-300;
    border-left: 1px solid $dvtx-fivef-blue-100;
  }

  &.five-f-file-upload-button.dvtx-border-color {
    &-gray {
      border: 1px solid $dvtx-fivef-blue-100;
    }

    &-primary {
      border: 1px solid $dvtx-fivef-skyblue-300;
      background-color: #e5f5fc;
    }
  }

  &.five-f-file-upload-button:hover {
    color: $dvtx-action-blue;
    background-color: #e5f5fc;
    border: 1px solid $dvtx-action-blue;
  }
}
