
.mat-tab-header {
  padding: $content-gap;
  border-bottom: none;
}

.mat-tab-label {
  font-size: 1rem;
  min-width: initial !important;

  &:first-child {
    padding-left: 0;
  }
}

.mat-ink-bar {
  height: 4px !important;
}

.mat-tab-label-active {
  opacity: 1 !important;
}


.mat-tab-body-content {
  transform: none !important;
}

.workflow-list {

  .workflow-list-item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 79px;
    border-bottom: 1px solid $dvtx-fivef-blue-50;
  }

  .workflow-list-checkbox-wrap {
    flex: 0 0 60px;
    padding: $content-gap;

    .mat-checkbox-layout {
      margin-bottom: 0;
    }
  }

  .workflow-list-label-wrap {
    flex: 0 0 200px;
    padding: $content-gap;
  }

  .workflow-list-datepicker-wrap {
    flex: 0 0 150px;
    padding: $content-gap;

    .dvtx-datapicker {
      padding-top: 0;

      .mat-input-suffix {
        margin-top: -2px;
      }
    }
  }

  .workflow-list-comment-wrap {
    flex: 1 1 auto;
    padding: $content-gap;

    .mat-form-field {
      width: 100%;
    }
  }

  .workflow-list-remove-item-btn-wrap {
    margin-left: auto;
    flex: 0 0 60px;
    padding: $content-gap;

    button {
      background: transparent;
      border: none;
      cursor: pointer;

      &:hover {
        color: $dvtx-danger;
      }
    }
  }

  .mat-input-wrapper {
    padding-bottom: 0;
  }

  .mat-input-underline {
    bottom: 0;
  }

  .mat-input-infix {
    border-top: none;
  }

}

.mat-form-field {
  display: block !important;
}

.contact-form {

  .mat-tab-header {
    padding: 0 24px;
    background-color: $dvtx-bg;
  }

  .mat-tab-body {
    padding: 0;
  }

  .mat-tab-body-content {
    overflow-x: hidden;
  }

}

@media (max-width: 991px) {

  .workflow-list {

    .workflow-list-item-header {
      display: none !important;
    }

    .workflow-list-checkbox-wrap {
      height: 73px;
    }

    .workflow-list-label-wrap {
      flex: 1 0 150px;
    }

    .workflow-list-checkbox-wrap,
    .workflow-list-label-wrap,
    .workflow-list-datepicker-wrap,
    .workflow-list-remove-item-btn-wrap {
      order: 1;
    }

    .workflow-list-comment-wrap {
      flex: 1 0 100%;
      order: 2;
    }

  }

}

@media (max-width: 480px) {

  .workflow-list {

    .workflow-list-label-wrap {
      font-weight: bold;
    }

    .workflow-list-datepicker-wrap {
      flex: 1 0 100%;
      order: 2;
    }
  }

}
