/*********************
Additional button styles
**********************/

// Floating button
.btn-floating {
    display: inline-block;
    color: $button-color-raised;
    position: relative;
    overflow: hidden;
    z-index: 1;
    width: $button-floating-size;
    height: $button-floating-size;
    line-height: $button-floating-size;
    padding: 0;
    background-color: $button-color;
    @include border-radius(50%);
    @extend .z-depth-1;
    transition: .3s;
    cursor: pointer;
    vertical-align: middle;
    margin: auto 0;
    i {
        width: inherit;
        display: inline-block;
        text-align: center;
        color: $button-color-raised;
        font-size: $button-large-icon-font-size;
        line-height: $button-floating-size;
    }
    &:hover {
        @extend .z-depth-1-half;
    }
    &:before {
        border-radius: 0;
    }
    &.btn-large {
        width: $button-floating-size * 1.5;
        height: $button-floating-size * 1.5;
        i {
            line-height: $button-floating-size * 1.5;
        }
    }
    &.btn-small {
        height: 47px;
        width: 47px;
        line-height: 0;
        .fa,
        .material-icons {
            font-size: 0.93rem;
            margin-top: 5px;
        }
    }
    &.btn-transparent{
        background:transparent;
        box-shadow:none;
        i {
            color: $dvtx-fir-green-100;
            font-size: 1.25rem;
            vertical-align: middle;
            line-height: 1;
            transition:all 0.3s ease;
        }
        &:hover{
            background:$dvtx-action;
            i{
                color:#fff;
            }
        }
    }
}
button, html [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: none;
}

// Floating Action Button (FAB)
.btn-fab {
    margin: 0;
    padding: 15px;
    font-size: 26px;
    width: 56px;
    height: 56px;
    &:hover {
        //.variations(#{""}, background-color, transparent);
    }
    &,
    .ripple-wrapper {
        border-radius: 100%;
    }
    &.btn-mini {
        width: 40px;
        height: 40px;
        padding: 13px;
        font-size: 15px;
    }
}

// Fixed Action Button
.fixed-action-btn {
    position: fixed;
    right: 23px;
    bottom: 23px;
    padding-top: 15px;
    margin-bottom: 0;
    z-index: 998;
    ul {
        left: 0;
        right: 0;
        text-align: center;
        position: absolute;
        bottom: 64px;
        margin: 0;
        li {
            margin-bottom: 15px;
        }
        a.btn-floating {
            opacity: 0;
        }
    }
}

// Transparent flat button
.btn-flat {
    background-color: transparent;
    position: relative;
    padding: 8px 30px;
    border: none;
    margin: 10px;
    text-transform: uppercase;
    text-decoration: none;
    outline: none !important;
    &:focus {
        background-color: transparent;
    }
}

// Rounded buttons
.btn-rounded {
    @include border-radius(10em);
}

.nav-pills {
    &.md-pills {
        .nav-item {
            .active {
                border: none;
                background-color: $primary-color;
                color: #fff;
            }
            &:hover {
                background-color: red;
                color: $mdb-color;
                font-weight: 500;
                border-left: none;
            }
        }
    }
}
