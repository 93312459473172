@import "../../../utils/var";
@import "../../../utils/mixins";

// .cdk-global-overlay-wrapper {
//   @media (min-width: 960px)  {
//     padding: 0 60px;
//   }
// }

.mat-dialog-container {
  position: relative;
  margin: 0 auto;
  padding: 0!important;
  width: 100%;
  min-width: 80vw;

  @media (min-width: 768px)  {
    min-width: 600px;
  }

  .mat-dialog-header {
    position: relative;
    padding: 2rem 4.286rem 2rem 2rem;
    background-color: white !important;

    .step-wrap {
      margin: 2.143rem 0 0 0;
      padding: 0;
    }
  }

  .mat-dialog-title {
    margin-bottom: 0;
    font-size: 1.5rem;
  }

  .mat-dialog-close {
    background-color: $dvtx-bg;
    position: absolute;
    top: 10px;
    right: 10px;
    color:$dvtx-cool-grey-500;
    &:hover {
      background-color: $dvtx-danger;
      color:white;
    }
  }

  .mat-dialog-content {
    margin: 0;
    padding: 2rem;

    .fa-exclamation-circle {
      font-size: 1.5rem;
    }

    .mat-dialog-actions:last-child {
      margin-bottom: 0;
    }
  }

  .mat-list-item {
    display: block;
    border-bottom: 1px solid $dvtx-fivef-blue-25;

    a {
      display: block;
      padding: $content-gap 2rem;
      background-color: $dvtx-white;

      &:hover {
        background: $dvtx-bg;
      }
    }

    h3 {
      font-weight: 500;
    }

    &:last-child {
      border-bottom: none;
    }

    *:last-child {
      margin-bottom: 0;
    }
  }

  .mat-dialog-footer {
    padding: 0.7143rem 2rem;

    .mat-dialog-actions {
      margin-bottom: 0;
    }

    .btn {
      flex: 0 1 240px;
    }
  }

  .mat-list {
    .mat-list-item {
      font-size: 1rem;
      padding: 14px;
      border-bottom: 1px solid $dvtx-fivef-blue-25;
      &.active {
        background-color: $dvtx-bg;
        color: $dvtx-action;
      }
    }
    .mat-item-icon {
      position: absolute;
      top: 0;
      &.mat-Suffix {
        right: -0.5em;
      }
      &.mat-Auffix {
        left: 0;
      }

    }
  }
}

.dialog-sm {
  width: 500px !important;

  .mat-dialog-container {
    min-width: inherit;
  }

  @media (min-width: 768px)  {
    min-width: 500px;
  }
}

.dialog-md {
  width: 640px !important;

  .mat-dialog-container {
    min-width: inherit;
  }
}

.dialog-lg {
  width: 780px !important;

  .mat-dialog-container {
    min-width: inherit;
  }
}

.dialog-xl {
  width: 960px !important;

  .mat-dialog-container {
    min-width: inherit;
  }
}

.dialog-confirmation {

  .mat-dialog-footer .btn {
    flex: 0 1 180px!important;
  }

}

.dialog-contact {

  .mat-dialog-content {
    padding: 24px;
  }
}
