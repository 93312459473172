// scss-lint:disable QualifyingElement

// Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css

// ==========================================================================
// Print styles.
// Inlined to avoid the additional HTTP request:
// http://www.phpied.com/delay-loading-your-print-css/
// ==========================================================================


  @media print {
    body, html {
      height: auto !important;
      -webkit-print-color-adjust: exact !important;
      margin: exact !important;
      padding: exact !important;
      font-family: exact !important;
      font-size: 12px !important;
      background-color: none;
      overflow: hidden !important;
    }

    input.mat-input-element, textarea.mat-input-element, input[type=text]:disabled, input[type=text]:disabled, input[type=text][readonly="readonly"], input[type=password]:disabled, input[type=password]:disabled, input[type=password][readonly="readonly"], input[type=email]:disabled, input[type=email]:disabled, input[type=email][readonly="readonly"], input[type=url]:disabled,
    input[type=url]:disabled, input[type=url][readonly="readonly"], input[type=time]:disabled, input[type=time]:disabled, input[type=time][readonly="readonly"], input[type=date]:disabled, input[type=date]:disabled, input[type=date][readonly="readonly"], input[type=datetime-local]:disabled, input[type=datetime-local]:disabled,
    input[type=datetime-local][readonly="readonly"], input[type=tel]:disabled, input[type=tel]:disabled, input[type=tel][readonly="readonly"], input[type=number]:disabled, input[type=number]:disabled, input[type=number][readonly="readonly"], input[type=search-md]:disabled, input[type=search-md]:disabled, input[type=search-md][readonly="readonly"],
    input[type=search]:disabled, input[type=search]:disabled, input[type=search][readonly="readonly"], textarea.md-textarea:disabled, textarea.md-textarea:disabled, textarea.md-textarea[readonly="readonly"]{
      color: black !important;
      border-bottom: 1px solid black !important;
      opacity: 1 !important;
    }
    body .dvtx-app .dvtx-bg-color.dvtx-bg-fivef-blue-50 {
      background-color: white !important;
    }
    button, .navbar, .quickstart, .mat-form-field-subscript-wrapper {
      display: none !important;
    }
    .wrapper,.container-fluid, .container, .content-gap-below {
      margin: 0 0 0 0 !important;
      padding: 0 0 0 0 !important;
    }
    p {
      margin-bottom: 8px !important;
    }
    mat-header-cell.mat-header-cell {
      text-align: left !important;
    }

    .mat-card {
      margin: 0px !important;
      padding: 8px !important;
    }
    .card-content-devided {
      padding: 0 !important;
    }
    .mat-card .mat-card-content  {
      padding: 0 !important;
    }
    .card-content-devided .mat-card-content, .mat-card-content, .card-base .mat-card-title, .card-base .mat-card-content {
      padding: 8px !important;
      margin: 0 !important;
    }

    .mat-card .mat-card-content.mat-card-table-content, .mat-form-field-appearance-legacy .mat-form-field-infix {
      padding: 0 !important;
    }
    .mat-card .mat-table .mat-row, .mat-card .mat-table .mat-header-row {
      padding: 0 8px !important;
    }
    .mat-form-field-appearance-legacy .mat-form-field-wrapper {
      padding-bottom: 0 !important;
    }
    .mat-form-field-infix .mat-input-element {
      // vertical-align: sub !important;
      padding-bottom: 0 !important;
    }
    .mat-form-field-appearance-legacy .mat-form-field-label {
      transform: none !important;
      position: relative !important;
      margin: auto !important;
      top: -8px !important;
      bottom: 0 !important;
      height: 100% !important;
    }
    .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
      bottom: 0px !important;

      display: none !important;
    }
    textarea.mat-input-element.cdk-textarea-autosize {
      min-height: 46px;
    }
    *,
    *::before,
    *::after,
    p::first-letter,
    div::first-letter,
    blockquote::first-letter,
    li::first-letter,
    p::first-line,
    div::first-line,
    blockquote::first-line,
    li::first-line {
      // Bootstrap specific; comment out `color` and `background`
      //color: #000 !important; // Black prints faster:
                                //   http://www.sanbeiji.com/archives/953
      text-shadow: none !important;
      //background: transparent !important;
      box-shadow: none !important;
    }

    a,
    a:visited {
      text-decoration: underline;
    }

    // Bootstrap specific; comment the following selector out
    //a[href]::after {
    //  content: " (" attr(href) ")";
    //}

    abbr[title]::after {
      content: " (" attr(title) ")";
    }

    // Bootstrap specific; comment the following selector out
    //
    // Don't show links that are fragment identifiers,
    // or use the `javascript:` pseudo protocol
    //

    //a[href^="#"]::after,
    //a[href^="javascript:"]::after {
    // content: "";
    //}

    pre {
      white-space: pre-wrap !important;
    }
    pre,
    blockquote {
      border: $border-width solid #999;   // Bootstrap custom code; using `$border-width` instead of 1px
      page-break-inside: avoid;
    }

    //
    // Printing Tables:
    // http://css-discuss.incutio.com/wiki/Printing_Tables
    //

    thead {
      display: table-header-group;
    }

    tr,
    img {
      page-break-inside: avoid;
    }

    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3;
    }

    h2,
    h3 {
      page-break-after: avoid;
    }

    // Bootstrap specific changes start

    // Bootstrap components
    .navbar {
      display: none;
    }
    .badge {
      border: $border-width solid #000;
    }

    .table {
      border-collapse: collapse !important;

      td,
      th {
        background-color: #fff !important;
      }
    }
    .table-bordered {
      th,
      td {
        border: 1px solid #ddd !important;
      }
    }

    // Bootstrap specific changes end
  }
