@import '../../../utils/var';
@import '../../../utils/mixins';

.selected-file {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &__name {
    @include text-crop();
    max-width: 100px;
  }

  &__clear {
    cursor: pointer;
    .mat-icon {
      color: map-get($dvtx-color-palette, danger);
      vertical-align: middle;
    }
  }

  .mat-icon {
    font-size: inherit;
    width: auto;
    height: auto;

    &.selected {
      color: $dvtx-green-300;
    }
  }
}
