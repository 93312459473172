//@import '../../../../../../node_modules/@circlon/angular-tree-component/css/angular-tree-component.css';
.toggle-children {
  width: auto;
  height: auto;
  background: none;
  transition: all .3s;

  &:before {
    font: normal 14px/1 FontAwesome;
    content: "\F054";
  }
}
.toggle-children-wrapper {
  padding: 2px 8px 5px 1px;
}
.toggle-children-wrapper-expanded .toggle-children {
      -webkit-transform: rotate(
    90deg
    );
      -ms-transform: rotate(90deg);
      transform: rotate(
    90deg
    );
    display: inline-block;
    position: relative;
    top: 1px;
}
