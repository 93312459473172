.five-f-mat-icon-md {
  height: 24px;
  width: 24px;
  font-size: 22px;
  padding: 3px;
}

.five-f-mat-icon-sm {
  height: 24px;
  width: 24px;
  font-size: 20px;
  padding: 4px;
}

.five-f-mat-icon-xs {
  height: 24px;
  width: 24px;
  font-size: 18px;
  padding: 4px;
}

.five-f-small-icon-button {
  mat-icon {
    font-size: 18px;
    width: 18px;
    height: 18px;
    cursor: pointer;
    line-height: 1rem;
    margin-bottom: 0.8rem;
    margin-left: -0.5rem;
  }
}

.mat-icon-button .mat-icon.five-f-mat-icon-sm {
  line-height: 15px;
}

.five-f-mat-icon-list-bullet {
  mat-icon {
    font-size: 20px;
    width: 20px;
    height: 20px;
    line-height: 2rem;
  }
}
