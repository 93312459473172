@import "src/lib/styles/utils/var";
@import "src/lib/styles/utils/mixins";

.mat-select {
  margin-top: 2px;
  .mat-select-trigger {
    width: 100%;
    padding: 0;
    height: auto !important;
    min-width: 1px;
  }

  .mat-select-value {
    padding: 10px 0;
  }

  .mat-select-placeholder {
    color: $dvtx-fivef-blue-300;
  }

  .mat-select-underline {
    background: $dvtx-fivef-blue-300;
  }

  &.mat-select-white {
    .mat-select-value {
      color: $dvtx-white;
    }

    .mat-select-arrow {
      color: $dvtx-white;
    }

    & + .mat-input-placeholder-wrapper {
      color: $dvtx-white;

      .mat-input-placeholder {
        color: $dvtx-white;
      }
    }
  }
}
