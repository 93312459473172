.drag-n-drop {
  background-color: $dvtx-blue-50;
  border: 1px dashed $dvtx-blue-50;
  padding: $base-gap;
  width: 100%;
  text-align: center;

  &:hover {
    background-color: #b9c6cd;
    border: 1px solid $dvtx-action;
  }

  @include mq-xs-down {
    padding: $content-gap;
  }

  &__icon {
    font-size: $base-size * 3;
    color: $dvtx-fir-green-300;

    .mat-icon {
      font-size: inherit;
      width: auto;
      height: auto;
    }
  }

  &__button {
    button {
      border: 1px solid $dvtx-cool-grey-100;
      margin-bottom: $content-gap*0.5;
      white-space: normal;
    }

    p {
      font-size: 1.286rem;
      color: $dvtx-fivef-blue-300;
    }
  }
}

.five-f-unset-width {
  width: unset;
}

.dvtx-text-icon-button {
  font-weight: 500;
}

.mat-icon {
  overflow: visible !important;
}

.five-f-elevation-z {
  &-0 {
    box-shadow: 0 0 0 !important;
  }
  &-1 {
    box-shadow: 0 4px 4px -4px #c9c9c9 !important;
  }
  &-2 {
    box-shadow: 0 8px 8px -8px #c9c9c9 !important;
  }
  &-3 {
    box-shadow: 0 12px 12px -12px #c9c9c9 !important;
  }
  &-4 {
    box-shadow: 0 16px 16px -16px #c9c9c9 !important;
  }
  &-5 {
    box-shadow: 0 20px 20px -20px #c9c9c9 !important;
  }
}

.five-f-card {
  @extend .five-f-elevation-z-1;
  border-radius: 4px !important;
  padding: 0 !important;

  .mat-card-header {
    .mat-card-header-text {
      flex: 100%;
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      margin: 0;
      padding: 0.75rem;

      .mat-card-title {
        padding: 0;
        background: white;
        h2 {
          font-size: 1.4em;
          font-weight: normal;
        }
      }

      .mat-card-subtitle {
        margin-bottom: 0;
        margin-top: 6px;
      }

      .mat-icon-button {
        height: 32px;
        width: 32px;
        margin: 0 0.25rem !important;
        align-items: center;
        display: inline-flex;
        line-height: 1;
        justify-content: center;
      }
    }
  }
  .mat-card-content {
    padding: 0.75rem !important;
  }

  background: white;

  &.five-f-card-blue-header {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;

    .mat-card-header {
      border-top-left-radius: 4px !important;
      border-top-right-radius: 4px !important;
      background-color: $dvtx-fivef-blue-450;
      color: white !important;

      .mat-card-header-text {
        background-color: $dvtx-fivef-blue-450;
        border-top-left-radius: 4px !important;
        border-top-right-radius: 4px !important;
        color: white !important;

        .mat-card-title {
          background-color: $dvtx-fivef-blue-450;
          color: white !important;

          h1,
          h2,
          h3,
          h4,
          h5 {
            color: white !important;
          }
          h2 {
            line-height: 32px;
          }
        }

        .mat-card-subtitle {
          background-color: $dvtx-fivef-blue-450;
          color: white !important;

          h1,
          h2,
          h3,
          h4,
          h5 {
            color: white !important;
          }
        }
      }
    }
  }
}

.five-f-icon,
.fife-f-icon {
  &-color-default {
    color: #7b8490;
  }
  &-color-dark {
    color: #384658;
  }
}

.mat-button-toggle-group {
  width: 100%;
  box-shadow: none !important;
  border-bottom: 1px solid $dvtx-cool-grey-100 !important;
  margin-bottom: 2rem;
  padding-top: 1rem;
  position: sticky;
  background: white;
  z-index: 9;
  top: 0;
  box-shadow: 0px 0px 10px white !important;
  .mat-button-toggle {
    flex: 1 0 auto;
    &-checked {
      background: none;
      border-bottom: 4px solid var(--five-f-action-color);
      font-weight: 400;
     // color: $dvtx-action-blue;
    }
    .mat-button-toggle-button {
      width: 100% !important;
      .mat-button-toggle-label-content {
        padding: 0.5rem 1rem;
        width: 100%;
        .mat-icon {
          margin-right: 0.5rem;
        }
      }
    }
  }
}

.five-f-overlay-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: map-get($z-index-set, z-modal) !important;
}

// Component: menu items
.five-f-button-group {
  width: auto !important;
  margin-bottom: 0 !important;
  padding: 0 !important;
  background: none !important;
  display: flex;
  align-items: center;
  height: 36px;
  overflow: unset !important;
  > button {
    height: 36px !important;
    box-shadow: none !important;
  }
}

.five-f-transparent-background {
  background-color: transparent !important;

  .mat-dialog-container {
    background-color: transparent !important;
  }
}

.five-f-transparent-background-w100-max-width {
  min-height: 80vh;

  .mat-dialog-inner-wrap {
    min-height: 80vh;
    .five-f-preview-browser {
      min-height: 80vh;

      .five-f-document-pdf-preview .mat-dialog-content {
        max-height: calc(100% - 20px);
      }

      .five-f-document-image-preview .mat-dialog-content {
        max-height: 75vh;
      }
    }
  }

  background-color: transparent !important;

  .mat-dialog-container {
    background-color: transparent !important;
    // For testing remove if not used.
    // max-width: 850px !important;
    // margin: auto;
  }
}

.five-f-dialogue-fullScreen {
  .mat-dialog-inner-wrap {
    .five-f-preview-browser {
      height: 100vh !important;
      &__metadata-container{
        max-height: 88vh !important;
        .custom-preview-details-container {
          max-height: 75vh !important;
        }
        .five-f-comments-tab {
          &__panel {
            max-height: 50vh;
            overflow: auto;
          }
        }
      }
    }
    .five-f-document-image-preview .mat-dialog-content {
      max-height: 88vh !important;
    }
  }
}

.five-f-borderless-editor {
  .fr-box.fr-basic.fr-top .fr-wrapper .fr-element.fr-view {
    border: 1px solid transparent !important;
  }
}

.five-f-editor-no-height {
  .fr-wrapper {
    height: auto !important;
    max-height: unset;
  }
}

.five-f-growing-editor {
  .fr-wrapper {
    min-height: 120px !important;
    height: auto !important;
    max-height: unset;

    .fr-element {
      min-height: 120px !important;
      height: auto !important;
      padding: 10px !important;
    }

    .fr-placeholder {
      padding-top: 10px;
      padding-left: 10px;
    }
  }
}

/**
 * === Example
 * Taken from move documents dialog to show selected result as tag-alike item.
 *
 * <mat-form-field style="width: 400px">
 *   <!-- Show selected process if set including remove button -->
 *   <div class="w-100 d-flex align-items-center justify-content-between five-f-tag-item"
 *        *ngIf="selectedTargetProcess">
 *       <div class="text-truncate">
 *       {{inputText(selectedTargetProcess)}}
 *       </div>
 *     <div class="pl-2">
 *       <button mat-button
 *               mat-icon-button
 *               matSuffix
 *               color="gray"
 *               [disableRipple]="true"
 *               aria-label="Clear"
 *               (click)="resetSearch(''); searchFieldRef.value = ''; $event.stopPropagation()">
 *         <mat-icon>close</mat-icon>
 *       </button>
 *     </div>
 *   </div>
 *   <input matInput ...
 *   ...
 */
.five-f-tag-item {
  position: absolute;
  top: -4px;
 // margin-left: 5px;
  padding: 2px 4px;
  border: 2px solid $dvtx-fivef-blue-100;
  border-radius: 3px;
  background-color: $dvtx-fivef-blue-25;
  font-size: 1.2rem;
  box-shadow: 0 4px 4px -4px #c9c9c9 !important;

  &:hover {
    background-color: $dvtx-fivef-blue-50;
    box-shadow: 0 8px 8px -8px #c9c9c9 !important;
  }

  button {
    line-height: 21px;
    height: 1.5em;
    width: 1.5em;

    mat-icon {
      width: 1em;
      height: 1.125em;
      line-height: 1.125 !important;
      font-size: 14px;
    }
  }
}

// Disable dotted disabled line of mat-input.
// To be used with mat-input. See move dialog for an example.
// Reset the disabled underline of the input by the normal stroked underline.
// We do not want to give the feeling the field is now disabled.
.five-f-tag-item-input.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient( to right, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 0.42) 33%, #c2c7cc 0 ) !important;
  background-size: 1px 100% !important;
  background-repeat: repeat-x !important;
}

/**
 * Action menu, e.g. for process state or external link settings view.
 * Setup: Two lines with subtext.
 */
.five-f-action-dropdown--with-subtext {
  margin-top: 10px;
  max-width: unset !important;

  .mat-menu-item {
    height: 4rem;
    line-height: 1;

    sub {
      margin-top: 0.1rem;
      font-size: 0.8rem;
      line-height: 1;
      color: $dvtx-cool-grey-300;
    }
  }
}

.five-f-indicator {
  position: relative;

  &--unread-count-icon-indicator {
    position: absolute;
    bottom: 14px;
    right: 0px;
    height: 6px;
    width: 6px;
    padding: 2px;
    box-sizing: content-box;
    background-color: var(--five-f-indicator-background-color-unread);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 19%);
    border-radius: 50%;
  }

  &--unread-count-indicator {
    font-size: 10px;
    line-height: 10px;
    text-align: center;
    white-space: nowrap;
    position: absolute;
    bottom: -7px;
    right: -7px;
    height: 10px;
    min-width: 10px;
    padding: 2px;
    box-sizing: content-box;
    color: var(--five-f-indicator-color-unread);
    border-radius: 50%;
    background-color: var(--five-f-indicator-background-color-unread);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 19%);
  }

  &--md {
    height: 1.2rem;
    width: 1.2rem;
    padding: 2px;
    font-size: 10px;
    font-weight: 500;
    line-height: 1;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.mat-icon-button.five-f-indicator .five-f-indicator--unread-count-icon-indicator {
  bottom: 1px;
  right: 3px;
}

/**
 * See also
 * - dvtx-collecto-resource-indicator
 */
.five-f-resource-indicators {
  flex: 0 0 30px;
  justify-content: center;
  display: inline-flex;
}

.five-f-resource-indicator {
  position: relative;
  cursor: pointer;

  .mat-icon {
    opacity: 0.6;
    margin-left: 4px;
  }

  i {
    font-size: 10px;
    position: absolute;
    margin: auto;
    height: 10px;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    z-index: 1;
    background: var(--five-f-indicator-background-color);
    color: var(--five-f-indicator-color);
    border-radius: 50%;
    min-width: 10px;
    padding: 2px;
    box-sizing: content-box;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
