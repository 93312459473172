/**
   * Signature overrides
   */
.five-f-signature-settings-default-image .five-f-signature-preview-action-buttons .add-button:hover {
  background: unset !important
}

.five-f-signature-settings-default-image__preview-container:hover {
  background-color: mat-color($accent-coloredFiveFTheme, A700) !important;
}
