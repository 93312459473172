/**
   * a-Tag.
   */
// START: Approach 1
a:not(.dropdown-item):not(.mat-list-item):not([dvtxfileupload]):not(.nav-link):not(.mat-tab-link) {
  // color: $theme-grantThornton-font-color;
  color: mat-color($primary-grantThorntonTheme) !important;
  text-decoration: none !important;
  transition: all .3s 0s cubic-bezier(0.4, 0, 0.2, 1);
}

a:not(.dropdown-item):not(.mat-list-item):not([dvtxfileupload]):not(.nav-link):not(.mat-tab-link).mat-icon-button {
  color: $theme-grantThornton-gray !important;
}

.active a,
a.active,
a:hover {
  color: mat-color($primary-grantThorntonTheme, A700) !important;
}

a.active:not(.nav-item),
.active:not(.nav-item) a,
a:not(.dropdown-item):not(.mat-list-item):not([dvtxfileupload]):not(.nav-link):not(.mat-tab-link):hover {
  // color: mat-color($primary-grantThorntonTheme, A700) !important;
  color: $theme-link-hover-color !important;
  text-decoration: underline !important;
  text-underline-offset: 0.08em;
}

a:not(.dropdown-item):not(.mat-list-item):not([dvtxfileupload]):not(.nav-link):not(.mat-tab-link).mat-icon-button:hover {
  // color: mat-color($primary-grantThorntonTheme, A700) !important;
  color: mat-color($primary-grantThorntonTheme, A700) !important;
}

/**
   * Navigation
   */
.navbar .navbar-nav .nav-item > a {
  // color: #1d1d1b !important;
  // color: mat-color($primary-grantThorntonTheme) !important;
  color: #1d1d1b !important;
  text-decoration: none !important;
}

.navbar .navbar-nav .nav-item.active > a {
  color: mat-color($primary-grantThorntonTheme) !important;
  text-decoration: none !important;
}

.navbar .navbar-nav .nav-item.active > a:hover {
  color: mat-color($primary-grantThorntonTheme) !important;
  text-decoration: none !important;
}

// FAQ link override
.navbar .navbar-nav.user-menu-nav .nav-item .nav-link-faq {
  max-width: 90px !important;
}

.nav-item:hover > a {
  color: mat-color($primary-grantThorntonTheme) !important;
  box-shadow: inset 0 -4px 0 0 mat-color($primary-grantThorntonTheme) !important;
}

.nav-item.active > a {
  color: mat-color($primary-grantThorntonTheme) !important;
  box-shadow: inset 0 -4px 0 0 mat-color($primary-grantThorntonTheme) !important;
}

/**
 * Project structure link color overrides.
 */
dvtx-process-tree-node, // inner nodes
mat-nested-tree-node,
mat-tree-node  { // leaves
  a,
  a:not(.dropdown-item):not(.mat-list-item):not([dvtxfileupload]):not(.nav-link):not(.mat-tab-link) {
    color: #1d1d1b !important;
    text-decoration: none !important;
    transition: none;

    .active {
      color: mat-color($primary-grantThorntonTheme) !important;
      font-weight: 700 !important;
      text-decoration: none !important;
    }

    &:hover,
    .active:hover {
      color: $theme-link-hover-color !important;
    }
  }

  a:not(.dropdown-item):not(.mat-list-item):not([dvtxfileupload]):not(.nav-link):not(.mat-tab-link):hover,
  a:not(.dropdown-item):not(.mat-list-item):not([dvtxfileupload]):not(.nav-link):not(.mat-tab-link) .active:hover {
    color: $theme-link-hover-color !important;
  }
}

// remove underlining from icons, preserve it on the text.
mat-nested-tree-node,
mat-tree-node {
  .dvtx-expand-item {
    a,
    a:not(.dropdown-item):not(.mat-list-item):not([dvtxfileupload]):not(.nav-link):not(.mat-tab-link) {
      color: #1d1d1b !important;
      text-decoration: none !important;
      transition: none;

      .active {
        color: mat-color($primary-grantThorntonTheme) !important;
        text-decoration: none !important;
        font-weight: 700 !important;
      }

      .active:hover,
      &:hover {
        // color: mat-color($primary-grantThorntonTheme) !important;
        color: $theme-link-hover-color !important;
      }

      &:hover > span {
        text-decoration: underline !important;
        text-underline-offset: 0.08em;
      }
    }
  }
}

dvtx-quick-process-status .navigate--to--collector:not(.no-hover-effect):hover {
  color: $theme-link-hover-color !important;
  background: none;
  transition: none;

  > span {
    text-decoration: underline !important;
    text-underline-offset: 0.08em;
  }
}

dvtx-locale-switcher {
  a {
    color: #1d1d1b !important;
    text-decoration: none !important;
  }
}

dvtx-truncated-description {
  .show--more--link span.link--content:hover {
    color: $theme-link-hover-color !important;

    .link--content--text {
      text-decoration: underline !important;
      text-underline-offset: 0.08em;
    }

    mat-icon {
      text-decoration: none;
    }
  }
}

.dvtx-participant-overview__process-section {
  .dvtx-project-structure-breadcrumps {
    color: $theme-grantThornton-font-color !important;

    a:not(.dropdown-item):not(.mat-list-item):not([dvtxfileupload]):not(.nav-link):not(.mat-tab-link) {
      color: $theme-grantThornton-font-color !important;
      mat-icon {
        color: $theme-grantThornton-font-color !important;
      }
    }

    a.current:not(.dropdown-item):not(.mat-list-item):not([dvtxfileupload]):not(.nav-link):not(.mat-tab-link) {
      color: mat-color($primary-grantThorntonTheme) !important;
      font-weight: 700 !important;
      mat-icon {
        color: mat-color($primary-grantThorntonTheme) !important;
      }
    }
  }
}

.dvtx-mark-read-info-line {
  span {
    &:hover {
      color: $theme-link-hover-color !important;

      a {
        color: $theme-link-hover-color !important;
      }
    }
  }
}
