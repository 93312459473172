dvtx-address-autocomplete {

  .mat-input-placeholder-wrapper {
    overflow: visible;
  }

}


.pac-container {
  top: 100% !important;
  left: 0 !important;
  right: 0 !important;
  width: auto !important;
}
