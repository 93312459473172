@use '@angular/material' as mat;
/**
 * Copyright 5FSoftware GmbH 2017
 *
 * Authors: Andreas Baier, Elena Mankivskaya
 *
 */

/* UTILS
//
// The util/ folder gathers all scss tools and helpers we’ll use across the project. Got a function? A mixin?
// Put it in there. This folder also contains var.scss file (sometimes _config.scss) which holds all global variables
// for the project (for typography, color schemes, and so on)
*/
@import "lib/styles/utils/var";
@import "lib/styles/utils/mixins";
@import "lib/styles/utils/fonts_mixins";
@import "lib/styles/utils/sidebarin";

/*
// Angular Material IO
//
// Custom angular material io configuration.
*/
@import "lib/styles/vendors/angular-material/_angular_material.io";

/* Wysiwyg
//
*/
@import "../node_modules/froala-editor/css/froala_editor.pkgd.min.css";
@import "../node_modules/froala-editor/css/froala_style.min.css";


// flag icon css
$flag-icon-css-path: '~flag-icon-css/flags' !default;
@import "flag-icon-css/sass/flag-icon";

/* BASE
//
// The base/ folder holds what we might call the boilerplate stuff for your project. In there, you might find the reset
// (or Normalize.css, or whatever), probably some stuff dealing with typography, and, depending on the project, maybe
// some other files.
*/
@import "lib/styles/base/typography";
@import "lib/styles/base/colors";
@import "lib/styles/base/layout";
@import "lib/styles/base/tables";
@import "lib/styles/base/components";
@import "lib/styles/layout/images";
@import "lib/styles/layout/document";
@import "lib/styles/layout/content_editable";
@import "lib/styles/layout/widgets";

/* LAYOUT
//
//
*/
@import "lib/styles/layout/icons";

@import "lib/styles/override/mat_cdk";
@import "lib/styles/override/mat_buttons";
@import "lib/styles/override/mat_form";
@import "lib/styles/override/mat_spinner";
@import "lib/styles/override/mat_icon";
@import "lib/styles/override/mat_input";
@import "lib/styles/override/mat_table";
@import "lib/styles/override/mat_tab";
@import "lib/styles/override/mat_expension_panel";
@import "lib/styles/override/mat_stepper";

// Print overrides
@import "lib/styles/layout/print";

/* PAGES
//
// If you have screen-specific styles, I think it’s cool to put them in a pages/ folder and in a file named after
// the page.
*/

/* THEMES
//
//
//
*/

// TODO: Please revise: zoom is not supported on current Firefox.
//       Zoom breaks iPad view, small avatars, tooltips on header and tooltips
//       on quick buttons, the main layout and the quick icons are displaced below the banner on small screens.
//       Web articles are describing it as a support feature derived by IE that should not be used in
//       production webpages because not all browsers have support.
//       Reference: https://caniuse.com/#feat=css-zoom
//body {
//  @include mq-xxl-down {
//    zoom: 90%;
//    recaptcha, iframe[title='recaptcha challenge'] {
//      zoom: 112%;
//    }
//  }
//  @include mq-xl-down {
//    zoom: 85%;
//    recaptcha, iframe[title='recaptcha challenge'] {
//      zoom: 117%;
//    }
//  }
//  @include mq-lg-down {
//    zoom: 80%;
//    recaptcha, iframe[title='recaptcha challenge'] {
//      zoom: 124%;
//    }
//  }
//}

@import "lib/styles/themes/default-theme";
@import "lib/styles/themes/grant-thornton-theme";
@import "lib/styles/themes/curacon-theme";
@import "lib/styles/themes/paperbird-theme";
@import "lib/styles/themes/colored-five-f-theme";
