@import "../../../utils/var";
@import "../../../utils/mixins";

.mat-button,
.mat-fab,
.mat-icon-button,
.mat-mini-fab,
.mat-raised-button {
  font-size: 0.8125rem;
  font-weight: normal;
  text-transform: uppercase;
}

.mat-button,
.mat-raised-button {
  &.wide {
    min-width: 180px;
  }
}

.mat-button,
.mat-raised-button,
.mat-icon-button {

  .mat-icon[mataffix] {
    margin-right: 0.5rem;
  }

  .mat-icon[matsuffix] {
    margin-left: 0.5rem;
  }

  &.mat-gray {
    color: $dvtx-fivef-blue-300;

    &:hover,
    &:focus {
      color: $dvtx-action;
    }
  }

  &.mat-warning {
    background: $dvtx-warning;
    color: white;
    &:hover,
    &:focus {
      background: $dvtx-warning-hover;
    }
  }
}

.mat-raised-button {
  .mat-icon {
    font-size: 1.429rem;
  }
}

.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
  color: $dvtx-white;
}

.btn_small {
  font-size: 1rem;
  padding: 0.875rem;
}

.btn-border-default {
  background: transparent;
  border: 1px solid $dvtx-cool-grey-100!important;
  color: $dvtx-fivef-blue-300!important;
  box-shadow: none;
  text-transform: none;

  &:hover {
    border-color: $dvtx-action!important;
    color: $dvtx-action!important;
    box-shadow: none;
  }
}
